/* You can add global styles to this file, and also import other style files */

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes draw-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes checkmark-fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 30px #197bbd;
    box-shadow: inset 0px 0px 0px 30px #197bbd;
  }
}

@keyframes checkmark-fill {
  100% {
    -webkit-box-shadow: inset 0px 0px 0px 30px #197bbd;
    box-shadow: inset 0px 0px 0px 30px #197bbd;
  }
}

@-webkit-keyframes scale-up-bounce {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  40% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-bounce {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  40% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes scale-up-bounce-spring {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  20% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-bounce-spring {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  20% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes scale-down-bounce {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@keyframes scale-down-bounce {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  20% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@-webkit-keyframes scale-up {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(25, 123, 189, 0.5);
    box-shadow: 0 0 0 0 rgba(25, 123, 189, 0.5);
  }

  50% {
    -webkit-box-shadow: 0 0 0 15px rgba(25, 123, 189, 0);
    box-shadow: 0 0 0 15px rgba(25, 123, 189, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(25, 123, 189, 0.5);
    box-shadow: 0 0 0 0 rgba(25, 123, 189, 0.5);
  }

  50% {
    -webkit-box-shadow: 0 0 0 15px rgba(25, 123, 189, 0);
    box-shadow: 0 0 0 15px rgba(25, 123, 189, 0);
  }
}

@-webkit-keyframes wobble {
  0%,
  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

@keyframes wobble {
  0%,
  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

@-webkit-keyframes modal-open {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes modal-open {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
}

@-webkit-keyframes modal-close {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}

@keyframes modal-close {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}

@media (max-width: 999px) {
  .quirks-fix--ios-instagram {
    height: auto;
    min-height: 100%;
    padding-bottom: 250px;
  }
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  overflow-x: hidden;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
}

b,
strong {
  font-weight: 500;
}

small {
  font-size: 0.875em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  line-height: 1em;
}

p {
  margin: 0;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  border: 0;
  max-width: 100%;
}

figure {
  margin: 0;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

form {
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  border: none;
  background: transparent;
  line-height: normal;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer;
}

button:-moz-focusring,
input[type="button"]:-moz-focusring,
input[type="reset"]:-moz-focusring,
input[type="submit"]:-moz-focusring {
  outline: 1px dotted;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

[dir="rtl"] input[id="checkout_email_or_phone"],
[dir="rtl"] input[id^="checkout_shipping_rates_shopify-Local_20Delivery"] {
  unicode-bidi: -moz-plaintext;
  unicode-bidi: plaintext;
  text-align: end;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

select::-ms-expand {
  display: none;
}

optgroup {
  font-weight: bold;
}

option {
  background-color: #fff;
  color: #000;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}

td,
th {
  padding: 0;
  font-weight: normal;
}

body {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  line-height: 1.3em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-font-smoothing: subpixel-antialiased;
}

.text-container > * + * {
  margin-top: 0.5714285714em;
}

.text-container * + .heading-1,
.text-container * + .heading-2,
.text-container * + .heading-3 {
  margin-top: 1.4285714286em;
}

a,
.link {
  text-decoration: none;
  color: #197bbd;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

a:hover,
.link:hover {
  color: #15669d;
}

.link--muted {
  color: inherit;
  text-decoration: underline;
}

h1,
.heading-1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  font-size: 1.7142857143em;
  line-height: 1.3em;
}

@media (min-width: 750px) {
  h1,
  .heading-1 {
    font-size: 2em;
  }
}

h2,
.heading-2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  font-size: 1.2857142857em;
  line-height: 1.3em;
}

.main h2,
.main .heading-2 {
  color: #333333;
}

.sidebar h2,
.sidebar .heading-2 {
  color: #323232;
}

.content-box h2,
.content-box .heading-2 {
  color: #333333;
}

.default-background h2,
.default-background .heading-2 {
  color: #333333;
}

h3,
.heading-3 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.3em;
}

.main h3,
.main .heading-3 {
  color: #333333;
}

.sidebar h3,
.sidebar .heading-3 {
  color: #323232;
}

.content-box h3,
.content-box .heading-3 {
  color: #333333;
}

.default-background h3,
.default-background .heading-3 {
  color: #333333;
}

p {
  line-height: 1.5em;
}

.emphasis {
  font-weight: 500;
}

.main .emphasis {
  color: #333333;
}

.sidebar .emphasis {
  color: #323232;
}

.content-box .emphasis {
  color: #333333;
}

.default-background .emphasis {
  color: #333333;
}

.main .small-text .emphasis {
  color: #545454;
}

.sidebar .small-text .emphasis {
  color: #535353;
}

.content-box .small-text .emphasis {
  color: #545454;
}

.default-background .small-text .emphasis {
  color: #545454;
}

.small-text {
  font-size: 0.8571428571em;
}

.main .small-text {
  color: #737373;
}

.sidebar .small-text {
  color: #717171;
}

.content-box .small-text {
  color: #737373;
}

.default-background .small-text {
  color: #737373;
}

.address {
  font-style: normal;
  line-height: 1.5em;
}

.address--tight {
  line-height: inherit;
}

.pickup-instructions {
  width: 200px;
  white-space: initial;
}

@media (max-width: 749px) {
  .hide-on-mobile {
    display: none;
  }
}

@media (min-width: 750px) {
  .show-on-mobile {
    display: none;
  }
}

.layout-flex {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: -0.2857142857rem;
}

.layout-flex--wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.layout-flex--tight-vertical {
  margin-bottom: -0.1428571429rem;
  margin-top: -0.1428571429rem;
}

.layout-flex--tight-vertical .layout-flex__item {
  padding-bottom: 0.1428571429rem;
  padding-top: 0.1428571429rem;
}

.layout-flex--loose-horizontal {
  margin-left: -0.5714285714rem;
  margin-right: -0.5714285714rem;
}

.layout-flex--loose-horizontal .layout-flex__item {
  padding-left: 0.5714285714rem;
  padding-right: 0.5714285714rem;
}

.layout-flex__item {
  padding: 0.1428571429rem;
}

.layout-flex__item--stretch {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.icon-svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
}

.icon-symbols {
  display: none;
}

.icon-svg--block {
  display: block;
}

.icon-svg--color-accent {
  color: #197bbd;
  fill: currentColor;
}

.icon-svg--color-black {
  color: #000;
  fill: currentColor;
}

.icon-svg--color-blue {
  color: #1990c6;
  fill: currentColor;
}

.icon-svg--color-gray {
  color: #9b9b9b;
  fill: currentColor;
}

.icon-svg--color-gray-dark {
  color: #595959;
  fill: currentColor;
}

.icon-svg--color-gray-light {
  color: #959595;
  fill: currentColor;
}

.icon-svg--color-red {
  color: #ce4549;
  fill: currentColor;
}

.icon-svg--color-white {
  color: #fff;
  fill: currentColor;
}

.icon-svg--color-yellow {
  color: #e7a321;
  fill: currentColor;
}

.main .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor;
}

.sidebar .icon-svg--color-adaptive-lighter {
  color: #8f8f8f;
  fill: currentColor;
}

.content-box .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor;
}

.default-background .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor;
}

.btn .icon-svg--color-adaptive-lighter,
.sp-modal-toggle .icon-svg--color-adaptive-lighter {
  color: #a3cae5;
  fill: currentColor;
}

.main .field__icon .icon-svg--color-adaptive-lighter,
.main .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor;
}

.sidebar .field__icon .icon-svg--color-adaptive-lighter,
.sidebar .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor;
}

.content-box .field__icon .icon-svg--color-adaptive-lighter,
.content-box .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor;
}

.default-background .field__icon .icon-svg--color-adaptive-lighter,
.default-background .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor;
}

.main .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.sidebar .icon-svg--color-adaptive-light {
  color: #717171;
  fill: currentColor;
}

.content-box .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.default-background .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.btn .icon-svg--color-adaptive-light,
.sp-modal-toggle .icon-svg--color-adaptive-light {
  color: #a3cae5;
  fill: currentColor;
}

.main .field__icon .icon-svg--color-adaptive-light,
.main .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.sidebar .field__icon .icon-svg--color-adaptive-light,
.sidebar .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.content-box .field__icon .icon-svg--color-adaptive-light,
.content-box .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.default-background .field__icon .icon-svg--color-adaptive-light,
.default-background .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor;
}

.icon-svg--size-10 {
  width: 10px;
  height: 10px;
}

.icon-svg--size-12 {
  width: 12px;
  height: 12px;
}

.icon-svg--size-14 {
  width: 14px;
  height: 14px;
}

.icon-svg--size-16 {
  width: 16px;
  height: 16px;
}

.icon-svg--size-18 {
  width: 18px;
  height: 18px;
}

.icon-svg--size-24 {
  width: 24px;
  height: 24px;
}

.icon-svg--size-32 {
  width: 32px;
  height: 32px;
}

.icon-svg--size-48 {
  width: 48px;
  height: 48px;
}

.icon-svg--size-64 {
  width: 64px;
  height: 64px;
}

.icon-svg--size-184 {
  width: 184px;
  height: 184px;
}

.icon-svg--rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-svg--inline-before {
  margin-right: 0.2857142857em;
}

.icon-svg--inline-after {
  margin-left: 0.2857142857em;
}

.icon-svg--flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.icon-svg--flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.icon {
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
}

.icon-background--payment-gateways {
  fill: #d9d9d9;
}

.icon-svg--spinner-button {
  width: 20px;
  height: 20px;
}

.icon--free-tag {
  width: 94px;
  height: 46px;
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/free-tag-small-c89fe2ddc93811e9a3cd1c616fd27fc66e90748260172d38f3332757dd124a10.png);
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/free-tag-small-5439598929990a936b18a6bf65080248b85b7ae7eca0e37b8b365b6b5138db38.svg),
    none;
}

@media (min-width: 750px) {
  .icon--free-tag {
    width: 152px;
    height: 80px;
    background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/free-tag-65a3840efd125a6ce3a6bf2da5ddbfc9d1597f96cdb0eec801b06fd85f464889.png);
    background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/free-tag-c2dd504ac09e85e0958c656eb3b00e9bedc4fbaeaaa6031c81b045674cc6b7eb.svg),
      none;
  }
}

.icon--shopify-pay-primary {
  width: 55px;
  height: 16px;
}

.icon--shopify-pay {
  width: 59px;
  height: 14px;
}

.icon--shopify-pay--dark {
  fill: #000;
}

.icon--shopify-pay--light {
  fill: #fff;
}

.icon--gift-card {
  width: 16px;
  height: 16px;
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/gift-card-123e3d1e90a7cfb1eb75e986ee9ede169b5f402d5b76fb51961c0a44297996fd.png);
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/gift-card-a262d4faf79983ea2372d68228388158c727c7b71b918807c0d8c49eedc632b9.svg),
    none;
}

.icon--offsite {
  width: 102px;
  height: 52px;
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/offsite-small-4670fa5c6b06022d0eb3c0403efeeff014fec796291433ff5c2112efa510ee06.png);
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/offsite-small-0d8fd34431b27358e5c54a5ad660f76f8d628fad88320450d799287a8654982f.svg),
    none;
}

@media (min-width: 750px) {
  .icon--offsite {
    width: 163px;
    height: 81px;
    background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/offsite-f3d4a55eef203adc0d1d16841d8ff45acad31063a7d2f6bcf0167ef475e668d5.png);
    background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/offsite-908d79d8d532f6af67d7cc99244ede733729c29379c349ee015fbcea71fd8274.svg),
      none;
  }
}

.icon-svg--re-order {
  width: 20px;
  height: 16px;
}

.icon-svg--spinner {
  -webkit-animation: fade-in 0.5s ease-in-out, rotate 0.5s linear infinite;
  animation: fade-in 0.5s ease-in-out, rotate 0.5s linear infinite;
}

.icon-svg--clickable {
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.icon-svg--clickable:hover {
  color: #15669d;
}

.icon-svg--center {
  position: absolute;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo {
  display: block;
  vertical-align: middle;
}

.logo__text {
  color: #333333;
}

.logo__image {
  display: inline-block;
}

.logo--right {
  text-align: right;
}

.logo--center {
  text-align: center;
}

.logo__image--small {
  max-height: 2.1428571429em;
}

.logo__image--medium {
  max-height: 2.8571428571em;
}

.logo__image--large {
  max-height: 3.5714285714em;
}

@media (min-width: 1000px) {
  .logo__image--small {
    max-height: 2.8571428571em;
  }

  .logo__image--medium {
    max-height: 4.2857142857em;
  }

  .logo__image--large {
    max-height: 5.7142857143em;
  }
}

body {
  color: #545454;
  background: white;
}

.content {
  overflow: hidden;
}

.wrap {
  display: block;
  margin: 0 auto;
  max-width: 40em;
  zoom: 1;
}

.wrap:after,
.wrap:before {
  content: "";
  display: table;
}

.wrap:after {
  clear: both;
}

@media (max-width: 999px) {
  .wrap {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 1em;
  }
}

@media (min-width: 1000px) {
  .wrap {
    padding: 0 5%;
    width: 90%;
    max-width: 78.5714285714em;
  }
}

@media (max-width: 999px) {
  .no-js .content .wrap {
    display: table;
  }

  .no-js .content .wrap .main {
    display: table-footer-group;
  }

  .no-js .content .wrap .sidebar {
    display: table-header-group;
  }

  .no-js .content .wrap .sidebar::after {
    display: none;
  }
}

@media (min-width: 1000px) {
  .main {
    width: 46%;
    padding-right: 6%;
    float: left;
  }
}

.main .logo {
  display: none;
}

@media (min-width: 1000px) {
  .page--logo-main .main .logo {
    display: block;
  }
}

.main__emphasis {
  font-weight: 500;
  color: #333333;
}

.main__small-text {
  font-size: 0.8571428571em;
  color: #737373;
}

.sidebar {
  position: relative;
  color: #535353;
}

.no-js .sidebar,
.no-generatedcontent .sidebar {
  background: #fafafa;
}

@media (min-width: 1000px) {
  .sidebar {
    width: 44%;
    padding-left: 4%;
    background-position: left top;
    float: right;
  }
}

.sidebar::after {
  content: "";
  display: block;
  width: 300%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  background: #fafafa;
  z-index: -1;
  -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset;
  box-shadow: 0 -1px 0 #e1e1e1 inset;
}

@media (min-width: 1000px) {
  .sidebar::after {
    left: 0;
    background-position: left top;
    -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
    box-shadow: 1px 0 0 #e1e1e1 inset;
  }
}

.sidebar__header {
  margin-bottom: 2.5em;
  display: none;
}

@media (min-width: 1000px) {
  .page--logo-sidebar .sidebar__header {
    display: block;
  }
}

.anyflexbox body,
.anyflexbox .content,
.anyflexbox .content .wrap,
.anyflexbox .main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.anyflexbox .content .wrap {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.anyflexbox .main__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.anyflexbox .banner {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (min-width: 1000px) {
  .anyflexbox .content .wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.page--hidden-breadcrumbs .breadcrumb,
.page--hidden-main-header .main__header {
  display: none;
}

@media (max-width: 749px) {
  .main {
    padding-top: 1.5em;
  }

  .main__content {
    padding-bottom: 1.5em;
  }
}

@media (min-width: 750px) {
  .main {
    padding-top: 1.5em;
  }

  .main__content {
    padding-bottom: 4em;
  }
}

@media (max-width: 999px) {
  .breadcrumb {
    padding-bottom: 1.5em;
  }
}

@media (min-width: 1000px) {
  .page--no-banner .main,
  .page--no-banner .sidebar {
    padding-top: 4em;
  }

  .page--banner .main,
  .page--banner .sidebar {
    padding-top: 2em;
  }

  .main__header {
    padding-bottom: 2em;
  }

  .sidebar__logo {
    margin-bottom: 2em;
  }

  .page--logo-main .breadcrumb {
    margin-top: 1em;
  }
}

table {
  width: 100%;
}

td,
th {
  padding-left: 1em;
}

td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left;
}

td:last-child,
th:last-child {
  text-align: right;
}

.product-table th,
.product-table td {
  padding-top: 0;
  padding-bottom: 0;
}

.product-table__header th {
  white-space: nowrap;
  font-weight: 500;
  color: #333333;
}

.product-table--loose th,
.product-table--loose td {
  padding-top: 1.1428571429em;
  padding-bottom: 1.1428571429em;
}

.product-table--extra-loose th,
.product-table--extra-loose td {
  padding-top: 1.7142857143em;
  padding-bottom: 1.7142857143em;
}

.product-table--bordered td,
.product-table--bordered th {
  border-bottom: 1px solid #e6e6e6;
}

.product-table--bordered tr:last-child td {
  border-bottom: 0;
}

.banner {
  padding: 1.5em 0;
  background: transparent;
  background-size: cover;
  display: none;
}

.page--logo-sidebar .banner {
  background: transparent;
}

@media (max-width: 999px) {
  .banner {
    display: block;
  }
}

.page--banner .banner {
  display: block;
}

@media (min-width: 1000px) {
  .page--banner .banner {
    padding-top: 6em;
    padding-bottom: 2em;
  }
}

.btn,
.sp-modal-toggle {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  background-color: #197bbd;
  background-clip: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 1em 1.7em;
  text-align: center;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
}

.btn:hover,
.sp-modal-toggle:hover {
  background-color: #135e90;
  color: white;
}

.btn:active,
.sp-modal-toggle:active {
  outline: none;
}

@media (max-width: 749px) {
  .btn,
  .sp-modal-toggle {
    width: 100%;
    padding-top: 1.75em;
    padding-bottom: 1.75em;
  }
}

.btn--size-small {
  padding: 0.9285714286em;
}

.btn--subdued {
  background-color: transparent;
  border-color: rgba(179, 179, 179, 0.5);
  color: #197bbd;
  font-weight: normal;
}

.btn--subdued:hover {
  background-color: rgba(25, 123, 189, 0.06);
  color: #15669d;
}

.btn--subdued:focus,
.btn--subdued:active {
  border-color: #135e90;
  -webkit-box-shadow: 0 0 0 1px #135e90 inset;
  box-shadow: 0 0 0 1px #135e90 inset;
}

.btn--loading {
  pointer-events: none;
  cursor: default;
}

.btn--loading .btn__content,
.btn--loading .btn__icon {
  opacity: 0;
}

.btn--loading .btn__spinner {
  -webkit-animation: rotate 0.5s linear infinite;
  animation: rotate 0.5s linear infinite;
  opacity: 1;
}

.js .main .btn--disabled {
  cursor: default;
  background: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.js .sidebar .btn--disabled {
  cursor: default;
  background: #c8c8c8;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.js .content-box .btn--disabled {
  cursor: default;
  background: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.js .default-background .btn--disabled {
  cursor: default;
  background: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn__content {
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.btn__content--subtitle {
  font-size: 0.8571428571em;
  color: #737373;
}

.button-group__button--active .btn__content--subtitle {
  color: #197bbd;
}

.btn__icon {
  margin-top: -0.2857142857em;
  margin-bottom: -0.0714285714em;
}

.btn__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.step__footer {
  z-index: 2;
  position: relative;
  margin-top: 1em;
}

.no-js .step__footer,
.no-anyflexbox .step__footer {
  zoom: 1;
}

.no-js .step__footer:after,
.no-js .step__footer:before,
.no-anyflexbox .step__footer:after,
.no-anyflexbox .step__footer:before {
  content: "";
  display: table;
}

.no-js .step__footer:after,
.no-anyflexbox .step__footer:after {
  clear: both;
}

@media (min-width: 750px) {
  .step__footer {
    margin-top: 1.5em;
  }
}

.step__footer__previous-link-content {
  vertical-align: middle;
}

.step__footer__previous-link,
.step__footer__info {
  display: block;
}

@media (max-width: 749px) {
  .step__footer__previous-link,
  .step__footer__info {
    padding-top: 1.5em;
    text-align: center;
  }
}

@media (min-width: 750px) {
  .step__footer__previous-link,
  .step__footer__info {
    float: right;
  }
}

.previous-link__icon {
  -webkit-transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  margin-right: 0.25em;
}

.step__footer__previous-link:hover .previous-link__icon {
  fill: #15669d;
  -webkit-transform: translateX(-5px) "";
  transform: translateX(-5px) "";
}

@media (min-width: 750px) {
  .step__footer__continue-btn {
    float: left;
  }
}

.step__footer__continue-btn-text {
  clear: both;
  padding-top: 0.75em;
}

@media (max-width: 749px) {
  .step__footer__continue-btn-text {
    text-align: center;
  }
}

@media (min-width: 750px) {
  .anyflexbox .step__footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .anyflexbox .step__footer__previous-link,
  .anyflexbox .step__footer__info {
    margin-left: 1.5em;
  }

  .anyflexbox .previous-link__icon {
    display: none;
  }

  .anyflexbox .step__footer__review-notice-text {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.edit-link {
  display: block;
  margin-top: 0.75em;
}

.section--express-checkout-selection-group-test {
  display: table;
  width: 100%;
}

@media (min-width: 750px) {
  .section--express-checkout-selection-group-test
    .section--contact-information {
    display: table-header-group;
  }
}

@media (min-width: 750px) {
  .section--express-checkout-selection-group-test
    .section--express-checkout-selection {
    padding-top: 3em !important;
    display: block;
  }
}

.section--express-checkout-selection-group-control {
  display: table;
  width: 100%;
}

.section--express-checkout-selection-group-control
  .section--contact-information {
  display: table-header-group;
}

.section--express-checkout-selection-group-control
  .section--express-checkout-selection {
  padding-top: 3em !important;
}

.notice + .step__sections {
  margin-top: 1.5em;
}

@media (min-width: 750px) {
  .notice + .step__sections {
    margin-top: 2.5em;
  }
}

.step__sections + form {
  padding-top: 2em;
}

@media (min-width: 1000px) {
  .step__sections + form {
    padding-top: 0;
  }
}

.section__delivery-option-group {
  padding-top: 3em;
}

.section__delivery-option-group:first-child {
  padding-top: 0;
}

.section {
  position: relative;
  padding-top: 2em;
}

@media (min-width: 750px) {
  .section {
    padding-top: 3em;
  }
}

.section:first-child {
  padding-top: 0;
}

.section--half-spacing-top,
.section--half-spacing-bottom + .section {
  padding-top: 1em;
}

@media (min-width: 750px) {
  .section--half-spacing-top,
  .section--half-spacing-bottom + .section {
    padding-top: 1.5em;
  }
}

.section__header {
  position: relative;
  margin-bottom: 1em;
}

@media (min-width: 750px) {
  .section__header {
    margin-bottom: 1.5em;
  }
}

.section__title {
  color: #333333;
}

.section__text {
  margin-top: 0.25em;
}

.section__hanging-icon {
  margin-bottom: 1em;
}

@media (min-width: 750px) and (max-width: 999px) {
  .section__hanging-icon {
    position: absolute;
    right: 100%;
    top: -0.4em;
    margin-right: 1.5em;
  }
}

@media (min-width: 1300px) {
  .section__hanging-icon {
    position: absolute;
    right: 100%;
    top: -0.4em;
    margin-right: 1.5em;
  }
}

.section__content {
  zoom: 1;
}

.section__content:after,
.section__content:before {
  content: "";
  display: table;
}

.section__content:after {
  clear: both;
}

.section__content__text {
  margin-top: 0.75em;
}

.section__content__policy {
  margin-top: 1em;
}

.section__content__column {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 2em;
}

.section__content__column:first-of-type {
  margin-top: 0;
}

@media (min-width: 750px) {
  .section__content__column {
    margin-top: 0;
    float: left;
  }
}

@media (min-width: 750px) {
  .section__content__column--half {
    padding: 0 0.75em;
    width: 50%;
  }

  .section__content__column--half:first-child {
    padding-left: 0;
  }

  .section__content__column--half:last-child {
    padding-right: 0;
  }
}

.section__footer {
  zoom: 1;
  margin-top: 1em;
}

.section__footer:after,
.section__footer:before {
  content: "";
  display: table;
}

.section__footer:after {
  clear: both;
}

@media (min-width: 750px) {
  .section__footer {
    margin-top: 1.5em;
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .section--page-title {
    margin-top: 1em;
  }
}

.section--subscription-agreement .field--error input {
  border-color: #e32c2b;
  -webkit-box-shadow: 0 0 0 1px #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b;
}

.has-modal body {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.js .modal-backdrop {
  -webkit-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out,
    background-color 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out,
    background-color 0.5s ease-in-out;
}

.modal-backdrop.modal-backdrop--centered-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.modal-backdrop.modal-backdrop--is-closing.modal-backdrop--fade-out-white {
  background-color: rgba(255, 255, 255, 0.8);
}

.modal-backdrop--is-visible {
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
}

.modal {
  background: #fff;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  color: #545454;
  min-height: auto;
  visibility: hidden;
}

@media (min-width: 750px) {
  .modal {
    border-radius: 8px;
    margin: 4em auto 5%;
    max-width: 60em;
    min-height: initial;
    width: 90%;
  }
}

.modal.modal--centered {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.modal-backdrop--is-visible .modal {
  -webkit-animation: modal-open 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  animation: modal-open 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.no-cssanimations .modal-backdrop--is-visible .modal {
  visibility: visible;
}

.modal-backdrop--is-closing .modal {
  -webkit-animation: modal-close 0.25s ease-in-out;
  animation: modal-close 0.25s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.modal__header {
  padding: 1.5em;
}

@media (min-width: 750px) {
  .modal__header {
    padding: 1.5em 2.5em;
  }
}

.modal__header {
  border-bottom: 1px solid #e6e6e6;
  zoom: 1;
}

.modal__header:after,
.modal__header:before {
  content: "";
  display: table;
}

.modal__header:after {
  clear: both;
}

.display-table .modal__header {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.modal__header__title {
  color: #333333;
}

.display-table .modal__header__title {
  display: table-cell;
  width: 100%;
}

.display-table .modal__header__title:only-child {
  display: block;
}

.no-js .modal__header__title,
.no-display-table .modal__header__title {
  float: left;
}

.modal__content {
  padding: 1.5em;
}

@media (min-width: 750px) {
  .modal__content {
    padding: 2.5em;
  }

  .modal__content p {
    font-size: 1.1428571429em;
  }
}

.modal__iframe {
  width: 100%;
}

.modal__loading-icon {
  display: block;
  margin: 1em auto;
}

.modal__close {
  position: relative;
  -webkit-transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  vertical-align: middle;
  padding-left: 0.75em;
  white-space: nowrap;
}

.display-table .modal__close {
  display: table-cell;
}

.no-js .modal__close,
.no-display-table .modal__close {
  float: right;
}

.modal__close-button {
  color: #737373;
}

.modal__close-button:hover {
  color: #545454;
}

.fieldset {
  margin: -0.4285714286em;
  zoom: 1;
}

.fieldset:after,
.fieldset:before {
  content: "";
  display: table;
}

.fieldset:after {
  clear: both;
}

.fieldset--inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.fieldset--inline .field {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 749px) {
  .fieldset--inline {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.fieldset--inline .btn,
.fieldset--inline .sp-modal-toggle {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0.4285714286em;
  width: auto;
}

@media (min-width: 750px) {
  .anyflexbox .fieldset--inline .btn,
  .anyflexbox .fieldset--inline .sp-modal-toggle {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fieldset-description {
  margin-top: 0.9285714286em;
}

.field {
  width: 100%;
  float: left;
  padding: 0.4285714286em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 750px) {
  .floating-labels .field--third {
    width: 33.3333333333%;
  }

  .floating-labels .field--two-thirds {
    width: 66.6666666667%;
  }

  .floating-labels .field--half {
    width: 50%;
  }

  .floating-labels .field--three-eights {
    width: 37.5%;
  }

  .floating-labels .field--quarter {
    width: 25%;
  }
}

.field__message {
  line-height: 1.3em;
  margin: 0.5714285714em 0 0.2857142857em;
}

.field__message--error {
  display: none;
  color: #e32c2b;
}

.field--error .field__message--error {
  display: block;
}

.field__message__icon {
  margin-right: 0.25em;
  vertical-align: -3px;
}

.field__label {
  text-align: left;
  font-weight: 500;
  margin: 0.5em 0;
  display: none;
}

.main .field__label {
  color: #333333;
}

.floating-labels .main .field__label {
  color: #737373;
}

.sidebar .field__label {
  color: #323232;
}

.floating-labels .sidebar .field__label {
  color: #737373;
}

.content-box .field__label {
  color: #333333;
}

.floating-labels .content-box .field__label {
  color: #737373;
}

.default-background .field__label {
  color: #333333;
}

.floating-labels .default-background .field__label {
  color: #737373;
}

.main .field--active .field__label {
  color: #545454;
}

.sidebar .field--active .field__label {
  color: #545454;
}

.content-box .field--active .field__label {
  color: #545454;
}

.default-background .field--active .field__label {
  color: #545454;
}

.no-js .field__label {
  display: block;
}

.floating-labels .field__label {
  font-size: 0.8571428571em;
  font-weight: normal;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 0.4285714286em;
  margin-left: 1px;
  padding: 0 0.9166666667em;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
}

.floating-labels .field--show-floating-label .field__label {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
}

.floating-labels .field__input-wrapper--icon-left .field__label {
  padding-left: 2.8571428571rem;
}

.floating-labels .field__input-wrapper--icon-right .field__label {
  padding-right: 2.8571428571rem;
}

.animate-floating-labels .field__label {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.field__label--visible {
  display: block;
}

.info__message {
  color: #737373;
  font-size: 0.8571428571em;
  line-height: 1.3333333em;
}

.field__input-wrapper {
  position: relative;
}

.field__input {
  border: 1px transparent solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0.9285714286em 0.7857142857em;
  word-break: normal;
  line-height: inherit;
}

.main .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9;
}

.main .field__input::-webkit-input-placeholder {
  color: #737373;
}

.main .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.main .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.main .field__input:-ms-input-placeholder {
  color: #737373;
}

.main .field__input:disabled {
  background-color: #fafafa;
  border-color: #cccccc;
}

.sidebar .field__input {
  background-color: white;
  color: #333333;
  border-color: #d5d5d5;
}

.sidebar .field__input::-webkit-input-placeholder {
  color: #737373;
}

.sidebar .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.sidebar .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.sidebar .field__input:-ms-input-placeholder {
  color: #737373;
}

.sidebar .field__input:disabled {
  background-color: whitesmoke;
  border-color: #c8c8c8;
}

.content-box .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9;
}

.content-box .field__input::-webkit-input-placeholder {
  color: #737373;
}

.content-box .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.content-box .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.content-box .field__input:-ms-input-placeholder {
  color: #737373;
}

.content-box .field__input:disabled {
  background-color: #fafafa;
  border-color: #cccccc;
}

.default-background .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9;
}

.default-background .field__input::-webkit-input-placeholder {
  color: #737373;
}

.default-background .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.default-background .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1;
}

.default-background .field__input:-ms-input-placeholder {
  color: #737373;
}

.default-background .field__input:disabled {
  background-color: #fafafa;
  border-color: #cccccc;
}

.animate-floating-labels .field__input {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.floating-labels .field--show-floating-label .field__input {
  padding-top: 1.5em;
  padding-bottom: 0.3571428571em;
}

.floating-labels
  .field--show-floating-label
  .field__input::-webkit-input-placeholder {
  color: transparent;
}

.floating-labels .field--show-floating-label .field__input:-moz-placeholder {
  color: transparent;
  opacity: 1;
}

.floating-labels .field--show-floating-label .field__input::-moz-placeholder {
  color: transparent;
  opacity: 1;
}

.floating-labels
  .field--show-floating-label
  .field__input:-ms-input-placeholder {
  color: transparent;
}

.field__input:focus,
.field__input-wrapper--flag-focus .field__input {
  outline: none;
  border-color: #197bbd;
  -webkit-box-shadow: 0 0 0 1px #197bbd;
  box-shadow: 0 0 0 1px #197bbd;
}

@media (-ms-high-contrast: active) {
  .field__input:focus,
  .field__input-wrapper--flag-focus .field__input {
    background-color: highlight;
  }
}

.field--error .field__input {
  border-color: #e32c2b;
  -webkit-box-shadow: 0 0 0 1px #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b;
}

.field__input-wrapper--icon-left .field__input {
  padding-left: 2.8571428571rem;
}

.field__input-wrapper--icon-right .field__input {
  padding-right: 2.8571428571rem;
}

.field__input-wrapper--flag-right .field__input {
  padding-right: 4.7142857143rem;
}

.field__input[data-expanding] {
  height: 2.8571428571rem;
  resize: none;
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
}

.field__additional-info {
  line-height: 1.3em;
  margin: 0.75em 0 0.25em;
  color: #333333;
}

.chrome .card-fields-container .field__label {
  will-change: transform;
}

.field__input--iframe-container {
  border-color: #fafafa;
  min-height: 3.1428571429em;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.floating-labels .field--show-floating-label .field__input--iframe-container {
  padding-bottom: 0;
  padding-top: 0;
}

.field__input--iframe-container::after,
.field__input--iframe-container::before {
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.field__input--iframe-container::before {
  background: #e6e6e6;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  width: 100%;
}

.field__input--iframe-container::after {
  -webkit-animation: load 1s infinite ease-in-out both;
  animation: load 1s infinite ease-in-out both;
  background: #fafafa;
  border-radius: 8px;
  height: 1em;
  margin-top: -0.5em;
  top: 50%;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.card-fields-container--loaded .field__input--iframe-container {
  height: auto;
}

.card-fields-container--loaded .field__input--iframe-container::after,
.card-fields-container--loaded .field__input--iframe-container::before {
  opacity: 0;
}

.card-fields-container--loaded .field__input--iframe-container::after {
  left: 100%;
}

.card-fields-container--transitioned .field__input--iframe-container::after,
.card-fields-container--transitioned .field__input--iframe-container::before {
  content: none;
}

.card-fields-container--loaded .field--active .field__input--iframe-container {
  outline: none;
  border-color: #197bbd;
  -webkit-box-shadow: 0 0 0 1px #197bbd;
  box-shadow: 0 0 0 1px #197bbd;
}

.card-fields-container--loaded .field--error .field__input--iframe-container {
  border-color: #e32c2b;
  -webkit-box-shadow: 0 0 0 1px #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b;
}

@-webkit-keyframes load {
  0% {
    left: 1em;
    width: 0;
  }

  50% {
    left: 1em;
    width: calc(100% - 2em);
  }

  100% {
    left: calc(100% - 1em);
    width: 0;
  }
}

@keyframes load {
  0% {
    left: 1em;
    width: 0;
  }

  50% {
    left: 1em;
    width: calc(100% - 2em);
  }

  100% {
    left: calc(100% - 1em);
    width: 0;
  }
}

.card-fields-iframe {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: inherit;
  display: block;
  font: inherit;
  -webkit-font-smoothing: inherit;
  height: 3.1428571429em;
  line-height: normal;
  width: 100%;
}

.card-fields-styling-options {
  display: none;
  position: relative;
  background-color: white;
  color: #333333;
  z-index: 22;
}

.field__input--zip {
  text-transform: uppercase;
}

.field__input--zip::-webkit-input-placeholder {
  text-transform: none;
}

.field__input--zip:-moz-placeholder {
  text-transform: none;
  opacity: 1;
}

.field__input--zip::-moz-placeholder {
  text-transform: none;
  opacity: 1;
}

.field__input--zip:-ms-input-placeholder {
  text-transform: none;
}

.field__prefix {
  display: none;
  pointer-events: none;
  position: absolute;
  border: 1px transparent solid;
  padding-top: 1.5em;
  padding-left: 0.7857142857em;
}

.field--with-prefix .field__input {
  -webkit-transition: initial;
  transition: initial;
}

.field--show-currency .field__input {
  padding-left: 2em;
}

.field--show-currency .field__prefix {
  display: block;
}

.field__input--select {
  padding-right: 2.0714285714em;
  white-space: nowrap;
}

.field__input--select:-moz-focusring {
  color: transparent;
  -moz-transition: color 0ms;
  transition: color 0ms;
}

.main .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333;
}

.sidebar .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333;
}

.content-box .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333;
}

.default-background .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333;
}

.field__caret {
  display: block;
  width: 2.1428571429em;
  height: 43%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.main .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid;
}

.sidebar .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid;
}

.content-box .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid;
}

.default-background .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid;
}

.field__caret-svg {
  position: absolute;
  margin-left: -2px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.field__icon {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 2.8571428571rem;
}

.field__icon:focus {
  outline: none;
}

.field__input-wrapper--icon-right .field__icon {
  right: 0;
}

.field__input-wrapper--icon-left .field__icon {
  left: 0;
}

.field__icon-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.anyflexbox .field__input-btn-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.anyflexbox .field__input-btn-wrapper .field__input-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.anyflexbox .field__input-btn {
  width: auto;
  margin-left: 0.8571428571em;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
}

.no-js .field__input-btn,
.no-anyflexbox .field__input-btn {
  margin-top: 0.8571428571em;
  padding: 1em 1.5em;
}

.combo-box {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px rgba(2, 2, 2, 0.15) solid;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.23);
  box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.23);
  margin-top: 0.4285714286em;
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  z-index: 3;
}

.combo-box--hidden {
  display: none;
}

.combo-box--with-footer .combo-box__options {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.combo-box--with-footer .combo-box__option:last-child {
  border-radius: 0;
}

.combo-box__options {
  display: block;
  overflow-y: auto;
  max-height: 230px;
}

.combo-box__option {
  color: #545454;
  cursor: pointer;
}

.combo-box__option:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.combo-box__option--selected {
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px transparent solid;
}

.combo-box__option--selected .combo-box__content {
  margin: -1px;
}

.combo-box__content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 0.9285714286em 0.7857142857em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.combo-box__content * {
  pointer-events: none;
}

.combo-box__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.7857142857em;
}

.combo-box__header__title {
  font-size: 0.8571428571em;
  font-weight: 400;
  line-height: 1.3em;
  margin: 0;
  text-transform: uppercase;
}

.main .combo-box__header__title {
  color: #737373;
}

.sidebar .combo-box__header__title {
  color: #737373;
}

.content-box .combo-box__header__title {
  color: #737373;
}

.default-background .combo-box__header__title {
  color: #737373;
}

.combo-box__header__button {
  color: #737373;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  padding: 0.7857142857em;
  margin: -0.7857142857em;
}

.combo-box__header__button:hover {
  color: #545454;
}

.combo-box__footer {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 0.7428571429em 0.7857142857em;
  position: relative;
  z-index: 4;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.content-box {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: 5px;
  color: #545454;
}

.main .content-box {
  border-color: #d9d9d9;
}

.content-box .content-box {
  border-color: #d9d9d9;
}

.content-box,
.content-box-spacing {
  margin-top: 1em;
}

.content-box:first-of-type,
.content-box-spacing:first-of-type {
  margin-top: 0;
}

.content-box__emphasis {
  font-weight: 500;
  color: #333333;
}

.content-box__small-text {
  font-size: 0.8571428571em;
  color: #737373;
}

.content-box__sub-content {
  padding-top: 0.2857142857em;
  font-size: 1em;
  color: #737373;
}

.content-box__row {
  padding: 1.1428571429em;
  position: relative;
  zoom: 1;
}

.content-box__row ~ .content-box__row {
  border-top: 1px solid #d9d9d9;
}

.content-box__row:after,
.content-box__row:before {
  content: "";
  display: table;
}

.content-box__row:after {
  clear: both;
}

.display-table .content-box__row {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.content-box__row:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.content-box__row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.content-box__row--main-content {
  display: table-row;
}

.content-box__row--main-content .apple-pay-logo {
  height: 19px;
  width: 47px;
  background-color: white;
  display: block;
  background-image: -webkit-named-image(apple-pay-logo-black);
}

.content-box__row--sub-content {
  display: table-row;
}

.content-box__row--tight-spacing-vertical {
  padding-top: 0.8571428571em;
  padding-bottom: 0.8571428571em;
}

.content-box__row--secondary {
  background-color: #fafafa;
}

.content-box__row--no-border {
  padding-bottom: 0;
}

.content-box__row + .content-box__row--no-border {
  border-top: none;
  padding-top: 0;
}

.content-box__row--no-border + .content-box__row {
  border-top: none;
}

.content-box__row--no-padding {
  padding: 0;
}

.content-box__row--with-footer {
  padding-bottom: 0.8571428571em;
}

.content-box__panel {
  padding: 1.1428571429em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fafafa;
}

.content-box__row--details {
  margin-left: 2em;
}

.content-box__row--details + .content-box__row--details {
  margin-top: 0.8571428571em;
}

.content-box__row--details p {
  line-height: 1.75em;
}

.display-table .content-box__row__stretch {
  display: table-cell;
  width: 100%;
}

.display-table .content-box__row__stretch:only-child {
  display: block;
}

.no-js .content-box__row__stretch,
.no-display-table .content-box__row__stretch {
  float: left;
}

.content-box__row__right {
  padding-left: 0.75em;
  white-space: nowrap;
}

.display-table .content-box__row__right {
  display: table-cell;
}

.no-js .content-box__row__right,
.no-display-table .content-box__row__right {
  float: right;
}

.content-box__hr {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  margin: 1.1428571429em 0;
}

.content-box__hr--tight {
  margin: 0.8571428571em 0;
}

.content-box--applied-reduction-code-list {
  margin-top: 0.8571428571em;
  background-color: #fafafa;
}

.content-box__header {
  zoom: 1;
}

.content-box__header:after,
.content-box__header:before {
  content: "";
  display: table;
}

.content-box__header:after {
  clear: both;
}

.display-table .content-box__header {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.content-box__header ~ .content-box__header {
  margin-top: 1.1428571429em;
}

.display-table .content-box__header__title {
  display: table-cell;
  width: 100%;
}

.display-table .content-box__header__title:only-child {
  display: block;
}

.no-js .content-box__header__title,
.no-display-table .content-box__header__title {
  float: left;
}

.content-box__footer {
  border-top: 1px solid #e6e6e6;
  padding-top: 0.8571428571em;
  margin-top: 0.8571428571em;
}

.subscription-shipping-rate-options {
  text-align: right;
}

.content-box__row.duties-selector__no-border {
  border-top: none;
}

@media (max-width: 999px) {
  .order-summary--transition,
  .order-summary--is-collapsed {
    overflow: hidden;
  }
}

@media (max-width: 999px) {
  .js .order-summary--is-collapsed {
    height: 0;
    visibility: hidden;
  }
}

.order-summary--transition {
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
}

.order-summary__section {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.order-summary__section ~ .order-summary__section {
  border-top: 1px solid rgba(175, 175, 175, 0.34);
}

@media (min-width: 1000px) {
  .order-summary__section {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .order-summary__section:first-child {
    padding-top: 0;
  }
}

@media (min-width: 1000px) {
  .order-summary__sections {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 8em);
    width: 100%;
  }

  .no-js .order-summary__sections,
  .no-anyflexbox .order-summary__sections {
    height: auto;
  }

  .order-summary__section--product-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    min-height: 7em;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .order-summary__section--product-list + .order-summary__section {
    border: 0;
  }

  .order-summary__section--product-list::before,
  .order-summary__section--product-list::after {
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    -webkit-transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
    transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
    width: 100%;
    z-index: 4;
  }

  .order-summary__section--product-list::before {
    background-color: transparent;
    top: 0;
  }

  .order-summary__section--product-list::after {
    background-color: rgba(175, 175, 175, 0.34);
    bottom: 0;
  }

  .order-summary__section--product-list .product-table {
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    position: relative;
    z-index: 1;
  }

  .order-summary__section--has-scroll::before {
    background-color: rgba(175, 175, 175, 0.34);
  }

  .order-summary__section__content {
    min-width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .no-js .order-summary__section__content,
  .no-touchevents .order-summary__section__content {
    overflow: hidden;
  }

  .no-js .order-summary__section__content:hover,
  .no-js .order-summary__section__content:focus,
  .no-touchevents .order-summary__section__content:hover,
  .no-touchevents .order-summary__section__content:focus {
    overflow-y: auto;
  }
}

.order-summary__scroll-indicator {
  background-color: rgba(50, 50, 50, 0.85);
  border-radius: 2em;
  bottom: 1.5em;
  color: #fafafa;
  font-size: 0.8571428571em;
  left: 50%;
  opacity: 0;
  padding: 0.6em 1.2em;
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(100%) rotateZ(360deg);
  transform: translateX(-50%) translateY(100%) rotateZ(360deg);
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  white-space: nowrap;
  z-index: 2;
}

.order-summary__section--is-scrollable .order-summary__scroll-indicator {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(0) rotateZ(360deg);
  transform: translateX(-50%) translateY(0) rotateZ(360deg);
}

.order-summary__section--has-scroll .order-summary__scroll-indicator {
  -webkit-transform: translateX(-50%) translateY(-100%) rotateZ(360deg);
  transform: translateX(-50%) translateY(-100%) rotateZ(360deg);
}

.order-summary__scroll-indicator .icon-svg {
  margin-left: 0.2142857143em;
  margin-top: -0.2142857143em;
}

.order-summary__emphasis {
  font-weight: 500;
}

.sidebar .order-summary__emphasis {
  color: #323232;
}

.content-box .order-summary__emphasis {
  color: #333333;
}

.order-summary__small-text {
  font-size: 0.8571428571em;
}

.sidebar .order-summary__small-text {
  color: #717171;
}

.content-box .order-summary__small-text {
  color: #737373;
}

.total-line th,
.total-line td {
  padding-top: 0.75em;
}

.total-line:first-child th,
.total-line:first-child td {
  padding-top: 0;
}

.content-box .total-line__name {
  color: #737373;
}

.total-line__name .reduction-code {
  margin-left: 0.5714285714em;
}

.total-line__name--top-aligned {
  vertical-align: top;
}

.total-line__price {
  padding-left: 1.5em;
  text-align: right;
  white-space: nowrap;
}

.total-line--shipping-policy-link {
  padding-top: 0.25em;
}

.total-line__duties-container {
  position: relative;
  display: inline;
}

.total-line-table__tbody + .total-line-table__tbody .total-line:first-child th,
.total-line-table__tbody + .total-line-table__tbody .total-line:first-child td,
.total-line-table__tbody + .total-line-table__footer .total-line:first-child th,
.total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  td {
  padding-top: 3em;
  position: relative;
}

.total-line-table__tbody
  + .total-line-table__tbody
  .total-line:first-child
  th::before,
.total-line-table__tbody
  + .total-line-table__tbody
  .total-line:first-child
  td::before,
.total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  th::before,
.total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  td::before {
  content: "";
  position: absolute;
  top: 1.5em;
  left: 0;
  width: 100%;
  height: 1px;
}

.sidebar
  .total-line-table__tbody
  + .total-line-table__tbody
  .total-line:first-child
  th::before,
.sidebar
  .total-line-table__tbody
  + .total-line-table__tbody
  .total-line:first-child
  td::before,
.sidebar
  .total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  th::before,
.sidebar
  .total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  td::before {
  background-color: rgba(175, 175, 175, 0.34);
}

.content-box
  .total-line-table__tbody
  + .total-line-table__tbody
  .total-line:first-child
  th::before,
.content-box
  .total-line-table__tbody
  + .total-line-table__tbody
  .total-line:first-child
  td::before,
.content-box
  .total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  th::before,
.content-box
  .total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  td::before {
  background-color: rgba(179, 179, 179, 0.34);
}

.payment-due-label__total {
  font-size: 1.1428571429em;
}

.sidebar .payment-due-label__total {
  color: #323232;
}

.content-box .payment-due-label__total {
  color: #333333;
}

.payment-due-label__taxes {
  display: block;
}

.sidebar .payment-due-label__taxes {
  color: #717171;
}

.content-box .payment-due-label__taxes {
  color: #737373;
}

.payment-due__price {
  font-size: 1.7142857143em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em;
}

.sidebar .payment-due__price {
  color: #323232;
}

.content-box .payment-due__price {
  color: #333333;
}

.payment-due__currency {
  font-size: 0.8571428571em;
  vertical-align: 0.2em;
  margin-right: 0.5em;
}

.sidebar .payment-due__currency {
  color: #717171;
}

.content-box .payment-due__currency {
  color: #737373;
}

.total-line--shipping-details,
.total-line--recurring-total,
.total-line--recurring-total-details {
  color: #717171;
}

.shipping_details__controls {
  outline: none;
}

.recurring_total__controls {
  cursor: pointer;
}

.shipping_details__controls.expand svg,
.recurring_total__controls.expand .recurring_total__chevron-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.shipping_details__controls.collapse svg,
.recurring_total__controls.collapse .recurring_total__chevron-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.recurring-total-label {
  white-space: nowrap;
  vertical-align: top;
}

.recurring-total-label-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.recurring-total-product-name,
.recurring-total-detail {
  white-space: normal;
  vertical-align: top;
}

.recurring-total-tooltip-wrapper {
  display: block;
  width: 2rem;
}

.reduction-code__icon {
  margin-right: 0.1428571429em;
  vertical-align: top;
}

.reduction-code__text {
  font-size: 1em;
}

.sidebar .reduction-code__text {
  color: #323232;
}

.content-box .reduction-code__text {
  color: #333333;
}

.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  color: #545454;
}

.full-page-overlay__wrap {
  margin: auto;
  width: 100%;
  padding: 10% 0;
}

.full-page-overlay__title {
  color: #333333;
  margin-bottom: 0.5em;
}

.full-page-overlay__content {
  margin: 0 auto;
  max-width: 36em;
  padding-left: 1em;
  padding-right: 1em;
  zoom: 1;
}

.full-page-overlay__content:after,
.full-page-overlay__content:before {
  content: "";
  display: table;
}

.full-page-overlay__content:after {
  clear: both;
}

.full-page-overlay__content form {
  margin: 1.5em 0;
}

.full-page-overlay__content:focus {
  outline: 0;
}

.full-page-overlay__icon {
  margin-bottom: 1.5em;
}

.full-page-overlay__illustration {
  margin-bottom: 1.5em;
}

.full-page-overlay__action {
  margin-top: 1.5em;
}

.tooltip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  bottom: 80%;
  right: -0.3333333333em;
  width: 180px;
  margin: 0 0 5px 0;
  padding: 0.75em 1em;
  border-radius: 4px;
  white-space: normal;
  font-size: 0.8571428571em;
  font-style: normal;
  text-align: center;
  color: #fff;
  display: block;
  z-index: 200;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  -webkit-transform: rotateX(20deg) scale(0.8);
  transform: rotateX(20deg) scale(0.8);
  -webkit-transform-origin: 90% 120%;
  transform-origin: 90% 120%;
  -webkit-backface-visibility: hidden;
  background-color: rgba(51, 51, 51, 0.9);
  visibility: hidden;
  opacity: 0;
}

.tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 18px;
  width: 0;
  border: 6px solid transparent;
  border-top-color: rgba(51, 51, 51, 0.9);
}

@media (min-width: 750px) {
  .tooltip {
    -webkit-transform-origin: center 120%;
    transform-origin: center 120%;
    right: 50%;
    margin-right: -90px;
  }

  .tooltip:after {
    right: 50%;
    margin-right: -6px;
  }
}

.has-tooltip {
  cursor: pointer;
}

.tooltip-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.tooltip-container .tooltip-control {
  position: absolute;
  height: 100%;
  width: 100%;
}

.tooltip-container.show-tooltip .tooltip,
.tooltip-container:hover .tooltip,
.tooltip-container:focus .tooltip,
.has-tooltip.show-tooltip .tooltip,
.has-tooltip:hover .tooltip,
.has-tooltip:focus .tooltip {
  -webkit-transform: rotateX(0deg) scale(1);
  transform: rotateX(0deg) scale(1);
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.tooltip--right-align {
  right: -130px;
  bottom: 108%;
}

.tooltip--right-align:after {
  right: 130px;
}

@media (min-width: 750px) {
  .tooltip--right-align {
    -webkit-transform-origin: center 120%;
    transform-origin: center 120%;
    right: 50%;
    margin-right: -90px;
  }

  .tooltip--right-align:after {
    right: 50%;
    margin-right: -6px;
  }
}

.tooltip--vertical-align {
  margin-top: -3px;
}

.breadcrumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.breadcrumb__item {
  display: inline;
  font-size: 0.8571428571em;
  color: #737373;
}

.anyflexbox .breadcrumb__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb__item--current {
  font-weight: 500;
  color: #333333;
}

.breadcrumb__chevron-icon {
  margin: 0 0.6428571429em;
}

.breadcrumb--center {
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main__footer {
  padding: 1em 0;
  border-top: 1px solid #e6e6e6;
}

.copyright-text {
  font-size: 0.8571428571em;
  color: #737373;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  img {
    height: 1.2rem;
  }
}

.copyright-text--left {
  text-align: left;
}

.copyright-text--center {
  text-align: center;
}

.copyright-text--right {
  text-align: right;
}

.policy-list {
  zoom: 1;
  text-align: center;
}

.policy-list:after,
.policy-list:before {
  content: "";
  display: table;
}

.policy-list:after {
  clear: both;
}

.policy-list__item {
  float: left;
  font-size: 0.8571428571em;
  margin-right: 1.5em;
  margin-bottom: 0.5em;
}

.policy-list__item--left {
  float: left;
  margin-right: 1.5em;
}

.policy-list__item--center {
  display: inline;
  float: none;
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.policy-list__item--right {
  float: right;
  margin-left: 1.5em;
  margin-right: 0;
}

.input-checkbox,
.input-radio {
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  vertical-align: -4px;
  border: 1px solid;
}

.main .input-checkbox,
.main .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

.main .input-checkbox:hover,
.main .input-radio:hover {
  border-color: #cccccc;
}

.main .input-checkbox:disabled,
.main .input-radio:disabled {
  background-color: #fafafa;
  border-color: #cccccc;
}

.sidebar .input-checkbox,
.sidebar .input-radio {
  border-color: #d5d5d5;
  background-color: white;
}

.sidebar .input-checkbox:hover,
.sidebar .input-radio:hover {
  border-color: #c8c8c8;
}

.sidebar .input-checkbox:disabled,
.sidebar .input-radio:disabled {
  background-color: whitesmoke;
  border-color: #c8c8c8;
}

.content-box .input-checkbox,
.content-box .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

.content-box .input-checkbox:hover,
.content-box .input-radio:hover {
  border-color: #cccccc;
}

.content-box .input-checkbox:disabled,
.content-box .input-radio:disabled {
  background-color: #fafafa;
  border-color: #cccccc;
}

.default-background .input-checkbox,
.default-background .input-radio {
  border-color: #d9d9d9;
  background-color: white;
}

.default-background .input-checkbox:hover,
.default-background .input-radio:hover {
  border-color: #cccccc;
}

.default-background .input-checkbox:disabled,
.default-background .input-radio:disabled {
  background-color: #fafafa;
  border-color: #cccccc;
}

.input-checkbox:focus,
.input-radio:focus {
  border-width: 2px;
  border-color: #197bbd;
}

.input-checkbox:checked,
.input-radio:checked {
  border-color: #197bbd;
}

.input-checkbox:checked:hover,
.input-radio:checked:hover {
  border-color: #197bbd;
}

.input-radio {
  border-radius: 50%;
}

.input-radio:checked {
  border-width: 7px;
}

.input-checkbox {
  border-radius: 4px;
}

.input-checkbox:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  width: 10px;
  height: 8px;
  margin-left: -5px;
  margin-top: -4px;
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/checkbox-tick-8f61c047ddf394995dc729e4ed5672dfd5185ceb994e4acca120c3ddc11e4012.png);
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/checkbox-tick-66a9a39f70a44a226223ef4c61a701d2d10afaa73ba8846a509fbe58a82f3f36.svg),
    none;
}

.input-checkbox:checked {
  border-width: 9px;
}

.input-checkbox:checked:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  opacity: 1;
}

.radio-wrapper,
.checkbox-wrapper {
  zoom: 1;
  margin-bottom: 1em;
}

.radio-wrapper:after,
.radio-wrapper:before,
.checkbox-wrapper:after,
.checkbox-wrapper:before {
  content: "";
  display: table;
}

.radio-wrapper:after,
.checkbox-wrapper:after {
  clear: both;
}

.display-table .radio-wrapper,
.display-table .checkbox-wrapper {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.radio-wrapper:last-child,
.checkbox-wrapper:last-child {
  margin-bottom: 0;
}

.radio-wrapper.content-box__row,
.checkbox-wrapper.content-box__row {
  margin-bottom: 0;
}

.radio__input,
.checkbox__input {
  padding-right: 0.75em;
  white-space: nowrap;
}

.display-table .radio__input,
.display-table .checkbox__input {
  display: table-cell;
}

.no-js .radio__input,
.no-display-table .radio__input,
.no-js .checkbox__input,
.no-display-table .checkbox__input {
  float: left;
}

.radio__label,
.checkbox__label {
  cursor: pointer;
  vertical-align: middle;
}

.display-table .radio__label,
.display-table .checkbox__label {
  display: table-cell;
  width: 100%;
}

.display-table .radio__label:only-child,
.display-table .checkbox__label:only-child {
  display: block;
}

.no-js .radio__label,
.no-display-table .radio__label,
.no-js .checkbox__label,
.no-display-table .checkbox__label {
  float: left;
}

.radio__label--active {
  color: #197bbd;
}

.radio__label--inactive {
  cursor: default;
}

.radio__label--disabled {
  cursor: default;
  color: #737373;
}

.radio__label__primary {
  cursor: inherit;
  font-family: inherit;
  vertical-align: top;
}

.display-table .radio__label__primary {
  display: table-cell;
  width: 100%;
}

.display-table .radio__label__primary:only-child {
  display: block;
}

.no-js .radio__label__primary,
.no-display-table .radio__label__primary {
  float: left;
}

.radio__label__accessory {
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap;
}

.display-table .radio__label__accessory {
  display: table-cell;
}

.no-js .radio__label__accessory,
.no-display-table .radio__label__accessory {
  float: right;
}

.checkout-sms-info {
  font-size: 12px;
  color: #757575;
}

.payment-method-wrapper .radio__label__accessory {
  vertical-align: middle;
}

@media (max-width: 749px) {
  .payment-method-wrapper .radio-wrapper {
    display: block;
  }

  .payment-method-wrapper .radio__label,
  .payment-method-wrapper .radio__label__accessory {
    display: block;
    padding-left: 0;
    text-align: left;
  }

  .payment-method-wrapper .radio__label__primary::after {
    content: "";
    display: block;
    height: 0.65em;
  }
}

.payment-icon {
  display: inline-block;
  width: 38px;
  height: 24px;
  -webkit-transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  -webkit-backface-visibility: hidden;
}

.blank-slate .payment-icon {
  vertical-align: middle;
}

.payment-icon--affinbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/affinbank-08c01bb1dae96d1ad1951d339d7e08c56bb400dbc0f7d631a273f9e99411bc7d.svg),
    none;
}

.payment-icon--affirm {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/affirm-01c74a53d8dba3f47a254b617c80fff80968cfef323f9d2f7000adf38e6f10db.svg),
    none;
}

.payment-icon--afterpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/afterpay-c814f73b45b605f4c618ce52eb8c67427d3ae3cb8addb872e621dfbabae0b5f0.svg),
    none;
}

.payment-icon--afterpay-paynl-version {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/afterpay_paynl_version-92ce6cbe49f8691c9f397731b08225859c06a2488c46bff78e9fe99b4d3d2778.svg),
    none;
}

.payment-icon--airtel-money {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/airtel_money-ba47466e863909cc190c6f7143887fc86bcbad8246a1fd788a5a1e0f5a42cc87.svg),
    none;
}

.payment-icon--airteltigo-mobile-money {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/airteltigo_mobile_money-a27fcf304fac6a38d4ea290c69ce2a538d92491a75324806c532634f47223b42.svg),
    none;
}

.payment-icon--alfamart {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/alfamart-0e2af778cccea1d57c8694748076f3a2ca2f84db8333fae0c60b9deac65b356d.svg),
    none;
}

.payment-icon--alipay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/alipay-6fc03147a0db525e1617dd147c40e1b7c6588a79461edc8db811adaa1cf85355.svg),
    none;
}

.payment-icon--alipay-hk {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/alipay_hk-d0e8325d855b0b78e7377ad25344fd710ab0e211dd9fa759ff1beb3076f21c58.svg),
    none;
}

.payment-icon--alliancebank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/alliancebank-620753c6a7fdfeab0dfcca395319ed76c06f617eafbd38da37e314883b9aea83.svg),
    none;
}

.payment-icon--amazon {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/amazon-92e856f82cae5a564cd0f70457f11af4d58fa037cf6e5ab7adf76f6fd3b9cafe.svg),
    none;
}

.payment-icon--ambank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ambank-89ecb816f616894547157ab3af4ee1cad2242784a27bc1ce4a4ca909b017845b.svg),
    none;
}

.payment-icon--american-express {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/american_express-2264c9b8b57b23b0b0831827e90cd7bcda2836adc42a912ebedf545dead35b20.svg),
    none;
}

.payment-icon--apple-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/apple_pay-f6db0077dc7c325b436ecbdcf254239100b35b70b1663bc7523d7c424901fa09.svg),
    none;
}

.payment-icon--arhaus {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/arhaus-030a95b00ca6f1202b029c9eb0726f741d81f0e69ab6fe7db7d9eef9fdbd66aa.svg),
    none;
}

.payment-icon--arvato {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/arvato-7a38b4b987355cc4f1d96ddb32865b5dfd8a1d461d9b52c4b5ba885729e3dfec.svg),
    none;
}

.payment-icon--ask {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ask-bc6ae56b263b7fe4a5707d74a0cbd19e7d23a743160e4df05aa216b68cb4be19.svg),
    none;
}

.payment-icon--atmbersama {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/atmbersama-1e799b2e6e61282e5749aacebf97c9da8f693b403c1eba5e5c7d0df211e26241.svg),
    none;
}

.payment-icon--au-kantan-kessai {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/au_kantan_kessai-4b626f71606119d1fdbd14ad228c8e574520ad792bb8ae9bde915a7dfad8607b.svg),
    none;
}

.payment-icon--axs {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/axs-1a4ddedcfc69afb470f7e1300f109c75b2f7b3ac5ec7312cbc1f0e83c2fdb486.svg),
    none;
}

.payment-icon--bancnet {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bancnet-843b4601d6aedcc483b8cfee40a687c1ec6af043e6fe67785d3f2e563202f165.svg),
    none;
}

.payment-icon--bancontact {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bancontact-4ccc47bbb25524ce02ac8a0041bcce3041615434e865bfc99edb637ceef193fa.svg),
    none;
}

.payment-icon--bangkokbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bangkokbank-0858d4c8e4b6e6fe609060333a0f7dfc303f6fe562f55ad7f4e9be02540cdcc7.svg),
    none;
}

.payment-icon--bankislam {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bankislam-e10fbd52ffd9bd7afe3287e8f5531ad5372992e4652e2bc0917e92d6ef84e3ed.svg),
    none;
}

.payment-icon--bankmuamalat {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bankmuamalat-719c5b2246f80d14a55bc32b5ff7099695d1a17a57718ba594df6a86705d3563.svg),
    none;
}

.payment-icon--bankrakyat {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bankrakyat-f5be4ff988f41dc6bebfc317194e1eea05317322cb60e9c660e2f3c30baaa147.svg),
    none;
}

.payment-icon--bc-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bc_card-291cba646568cc2fc39c8f3ec04ffe3193a77e6323af8173e29b48ab422562bf.svg),
    none;
}

.payment-icon--bca {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bca-847683bf0b4df5183c1667c547fec695ccde8fff3799c4975445a6374a451deb.svg),
    none;
}

.payment-icon--bdo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bdo-246949f2b5f41934b772c4464e664275a6c6f5cfdba1f158bc53d45a01f6f46c.svg),
    none;
}

.payment-icon--belfius {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/belfius-8b2b5dd319c79afe6dbe9aaeece6193d89d1a30232e53fa1394ba3978f1e79b4.svg),
    none;
}

.payment-icon--benefit {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/benefit-22e571923c4b2de66723758244e9549becb7e69bcda44b09f3f82e0876f616d7.svg),
    none;
}

.payment-icon--bigc {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bigc-c2efdd6d98087c5d64d8a6533f3cd84b6b0c5524985d06c5d3c5cf2dd8af93b1.svg),
    none;
}

.payment-icon--billease {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/billease-54669d23b38bfa29713c0a1bf6ebad9a9a73fb03b0c48443d39a325fc67a0438.svg),
    none;
}

.payment-icon--billink {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/billink-8d269a50b524be530eea52946a9a85a054e717037743e37579d99dbbc457ff3e.svg),
    none;
}

.payment-icon--bitcoin {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bitcoin-e41278677541fc32b8d2e7fa41e61aaab2935151a6048a1d8d341162f5b93a0a.svg),
    none;
}

.payment-icon--bitcoin-cash {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bitcoin_cash-5bcc0af0a5ebe647f8da2aae1f8382d7854796dc3bc2450985a9da832d4d2bbc.svg),
    none;
}

.payment-icon--blik {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/blik-39d5ec5cd0447f852cbf4ea6e98b88c503cedf1f6026a3eb36ea1617c08b09b4.svg),
    none;
}

.payment-icon--bni {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bni-c98e0f595f7bdda4814dbd2608ec75c7603f157919ba4748b0432f1e8962cdf1.svg),
    none;
}

.payment-icon--bogus {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bogus-13c83bc142b1d1b69905f99e38a897f75581e477a1233254ce01e64b3e3ae2b8.svg),
    none;
}

.payment-icon--bogus-app-coin {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bogus_app_coin-36cbb31f29677280f9883cd115a79a9f5d4a2e8e4427b298a48ea69a1bf8d176.svg),
    none;
}

.payment-icon--boleto {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/boleto-50666ca3aee193c64e41a2803fe4a6d6c5e1a27d03c5e44ce97e3aa82fb6deb2.svg),
    none;
}

.payment-icon--boost {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/boost-b1ab9e2c658caf6e10852175ae36413be2e5e18f9b76a1a38c5b336d4c9afc5b.svg),
    none;
}

.payment-icon--bpi {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bpi-fb71452e7dc550025134bd80a4b2b70551b417b66d42a50a66ee70b370977662.svg),
    none;
}

.payment-icon--bread {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bread-960e45c88dc6042693ee96b09b5e2add4930cc5a3d6bc4d6ff78f96fc8501bc0.svg),
    none;
}

.payment-icon--bri {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bri-8ee8c6fe5fd5500f54fa0dcf07052d341f10fc709357b6d54a9f1411e9ea798e.svg),
    none;
}

.payment-icon--bri-direct-debit {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bri_direct_debit-7fbdc0108aec7a8c41cc5c9c31edec5e41c20fcd19042cb5208c5790e68d9aac.svg),
    none;
}

.payment-icon--bsn {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bsn-36050d9c33fc6343488c656ed26f1212729cc297e704f92a09c63bf26a8c49a8.svg),
    none;
}

.payment-icon--cartes-bancaires {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/cartes_bancaires-ad50cf789d5354f733fea24cf4d0a73b4a2843080e3b5607a6f05445c2cd206b.svg),
    none;
}

.payment-icon--cash {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/cash-aca0520e6afe3967a5ec0566e9cab5dc3e39678976be8e10150a152f4d889f03.svg),
    none;
}

.payment-icon--cimb {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/cimb-c8c7c9d3f502553190be05539974f4dfd24307a5b1f6987f709e5c1299c18529.svg),
    none;
}

.payment-icon--cimbclicks {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/cimbclicks-abc62744bc119b6308bd93bcb116264187f3e5c8bd0832fc5286a7f75bccd744.svg),
    none;
}

.payment-icon--circlek {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/circlek-58c13e0385bfa6b02cf8eceac9ac41af35d0c0e104cf438d6298e20fe68af1aa.svg),
    none;
}

.payment-icon--citadele {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/citadele-657beca0bcffe6910cb87833033e4e7d94c19c56af6d86ac4c638443c572078a.svg),
    none;
}

.payment-icon--clearpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/clearpay-0e8e2d6e48d0dabaccf0e181ecf01b19f0d479ab032f48682bedcfa138983bd6.svg),
    none;
}

.payment-icon--coinsph {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/coinsph-eea7ccd6c9bf7f03d0a483659404a532d0c2c1cfda5d391c18f58ee54010e53c.svg),
    none;
}

.payment-icon--collector-bank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/collector_bank-83d197c8b12779b1f7e6d48462142c1c41885e135b4e9614584d5fdd4ceda31b.svg),
    none;
}

.payment-icon--coop {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/coop-487cae323b681272e988a792e973eb29e31468904483c0ccd765577d98a40d2f.svg),
    none;
}

.payment-icon--d-barai {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/d_barai-67c8337ed881cc9557f85cf1185761d8f9f6c4c297849a02a9d0fdee686455a8.svg),
    none;
}

.payment-icon--dai {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dai-58acead046f7352800bb5fda0893dfd8a044aa4611d18f0a654012dc9dda1a5b.svg),
    none;
}

.payment-icon--dailyyamazaki {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dailyyamazaki-68b5213c2536d408c669d39ccfb9d068a895d5f7ab121e3bc3e3fe91b74952f4.svg),
    none;
}

.payment-icon--dana {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dana-4807af397acf697f04dd5d4a2f45813003dc68adc9184f88ae14e46af458d061.svg),
    none;
}

.payment-icon--dankort {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dankort-a92b320b417b7c123265e1e4fe134935ac76ec7e297be9b02a5ef76b182a29cc.svg),
    none;
}

.payment-icon--danske-bank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/danske_bank-5d0229a9de7b776cef96ac84a78738697313e3056de46ad9f387a615492a2d38.svg),
    none;
}

.payment-icon--dash {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dash-79c6868e5d0012a312de5a0bc1e383d98a316f7ee0492d40124671c4d9994aca.svg),
    none;
}

.payment-icon--deutschebank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/deutschebank-cfb68056f87aca2283da71581a9f21ea940d38d976737dc451d93bc5785b86cf.svg),
    none;
}

.payment-icon--diners-club {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/diners_club-16436b9fb6dd9060edb51f1c7c44e23941e544ad798282d6aef1604319562fba.svg),
    none;
}

.payment-icon--discover {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/discover-cc9808e50193c7496e7a5245eb86d5e06f02e2476c0fe70f2c40016707d35461.svg),
    none;
}

.payment-icon--dnb {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dnb-6447b501736e8323a9b3dec1ea6318d8c776dcf57e37f8ca003f55191ce2e1d4.svg),
    none;
}

.payment-icon--docomo-barai {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/docomo_barai-61fab4f51197d9bdefec40d0b8b5fd6d2ebe2668d39cfc75ae9be84e915251ba.svg),
    none;
}

.payment-icon--dogecoin {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dogecoin-40c07eb6559d1c47a2ac893d14a4d27cdfad770df3413fb3e49ab51a18c8961d.svg),
    none;
}

.payment-icon--dwolla {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/dwolla-afe7aa3f31ed9f5aaf470e495448ee3f17a139aa8692a50d117571174726ce8d.svg),
    none;
}

.payment-icon--ebucks {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ebucks-d41d07f09abfc787ff48463cdb3e6f05aa6bd76b6954d905d3f26ee2c38ca10c.svg),
    none;
}

.payment-icon--ecpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ecpay-393b90a5e28555079fc291a58a72e56a1fdc201d87ac2d171a52a548005992ef.svg),
    none;
}

.payment-icon--eft-secure {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/eft_secure-fd1c18f610b963c70bcbcf236b26221e0193cd9ba97e03200f62591ac10d758c.svg),
    none;
}

.payment-icon--eghl {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/eghl-00e04bdf86ff5f2b795ed57dfd9b50917269719f27572a0a412f81a16627d51b.svg),
    none;
}

.payment-icon--elo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/elo-bae665edd62be838e1d8ce585b4fbaa26bc3ab0acccbda0b00864d7298895d87.svg),
    none;
}

.payment-icon--elv {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/elv-8dfb2600dc7fa9cfd33a33576542cdd929ed7b74d9f8ef6fb2862dfeb03342d7.svg),
    none;
}

.payment-icon--enets {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/enets-7ea95d3aac6ed0dca6b6b801f6ed6ec52e753144a45234bf3de43c18e0b90259.svg),
    none;
}

.payment-icon--eos {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/eos-93d1fb5eacb3370ff7121ee018cf7f6ff40766bb8bdb10e72d966975b1764c3b.svg),
    none;
}

.payment-icon--epayments {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/epayments-24d4b721ecfcdf7cee62181de2803630605ea9e8a214b7e410964bcc080d9129.svg),
    none;
}

.payment-icon--eps {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/eps-1da276d63cb986aff9d9c50ac1ea76c3d06670b5b32741399f080321c997386e.svg),
    none;
}

.payment-icon--esr-paymentslip-switzerland {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/esr_paymentslip_switzerland-0e00cd25a6b2c0b0d39c22bc75c1d68065b22e5d8ea2d45afbd71db367d6e9e8.svg),
    none;
}

.payment-icon--ethereum {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ethereum-5ddd16af409ae6bd61c9ebb5b3f1bbec913e7e92fa98d4b0330e192ee1531839.svg),
    none;
}

.payment-icon--facebook-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/facebook_pay-6dd2d5299387dd7ee76f136125a3dee928b3343af710c36b074192dd5ffe50b1.svg),
    none;
}

.payment-icon--familymart {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/familymart-8b7f2baff828541efeeeb9b934894bdb6a53561f1a5e2d0b24b2b9fec72b5cdb.svg),
    none;
}

.payment-icon--farmlands {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/farmlands-40210f40faf7e95b83b708c08b037ce456d353102e2c6063384a0d972cf3cced.svg),
    none;
}

.payment-icon--fashioncheque {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/fashioncheque-3fe8f93e072b0a76124d74d922f7ed60bc31f6912a0636db9855f3877b70aedd.svg),
    none;
}

.payment-icon--flexiti {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/flexiti-605283301fc62da35fe5cb607b3d1dd25348f5bbe7a9a7723f5b55fa69e3deb2.svg),
    none;
}

.payment-icon--forbrugsforeningen {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/forbrugsforeningen-99ffce51f2e166271aa285e1497d7feecec72a562c2b97298e6bc3504931f99d.svg),
    none;
}

.payment-icon--fps {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/fps-38615d4d8a5964e6985aa9fbf523b8e689fcd5a0af9a052f99ea36d6db2ba7cb.svg),
    none;
}

.payment-icon--fpx {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/fpx-ec7b2f24c5d2c9b50fa911379b2ea048cb29342ab1303e88c8984b0544603455.svg),
    none;
}

.payment-icon--freecharge {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/freecharge-cda66ce4e67c8edc373c16b4697bdee48a929f4f346404e9654df4498da439f8.svg),
    none;
}

.payment-icon--gcash {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/gcash-bf77f3d4f7896d286920aa41cbb7a1050fa4090a8e8932474d1e1272a1581236.svg),
    none;
}

.payment-icon--generic {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/generic-dfdcaf09b6731ca14dd7441354c0ad8bc934184eb15ae1fda6a6b9e307675485.svg),
    none;
}

.payment-icon--gift-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/gift-card-383f5d7b02d3062bf842ea917d2deba5d6b27e737b69f895a82f594405c31c23.svg),
    none;
}

.payment-icon--giropay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/giropay-6f774866c639b913c0d455fbc0d166710e827f55aff48b1931f02862edfcc65b.svg),
    none;
}

.payment-icon--givacard {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/givacard-914276bb1fd2df55cf3665d497840d06961b23fe30914fb319c9e39963c357ae.svg),
    none;
}

.payment-icon--gmo-postpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/gmo-postpay-c2fc292950708461e6eb2b8ad98f4ce883b5affee253d1cee6c9af4fd742a02d.svg),
    none;
}

.payment-icon--google-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/google_pay-c66a29c63facf2053bf69352982c958e9675cabea4f2f7ccec08d169d1856b31.svg),
    none;
}

.payment-icon--google-wallet {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/google_wallet-1fa0064d9dc27dbdb3330fdfb06e58da9410cc5645c9e08e1112f59c9ea80be0.svg),
    none;
}

.payment-icon--grabpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/grabpay-506fdfa08ee0a8e4d3dcd506ee025392c5659269badaa1f477e471b5a2c09780.svg),
    none;
}

.payment-icon--hana-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/hana_card-8d87b1bcdcff3252670f6a411c60e088f76da0f2e38f4575a6a4c8a355e92203.svg),
    none;
}

.payment-icon--hongleongbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/hongleongbank-6ac878054de5397c8e6d42f507c2832cf77de8a60e833fa2907cf773bf9434d4.svg),
    none;
}

.payment-icon--hongleongconnect {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/hongleongconnect-00a9e776695315b6d8f14dbaa00ba1790c9909d61660058e682d89fa0a43e0fe.svg),
    none;
}

.payment-icon--hsbc {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/hsbc-673a14bc66d9427e52b159a29781ee114bee211448aed13809b022d7c3f4fbb2.svg),
    none;
}

.payment-icon--hyper {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/hyper-be73bbfeb6d6393ad95172d5b431c64539b161673c86f0cf62ac3946de8291f6.svg),
    none;
}

.payment-icon--hypercard {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/hypercard-2ed68c576f3d6b5b34fecdbd700f8d5b95cfa18de92b51c3fcc6cc7e6bd3a6f0.svg),
    none;
}

.payment-icon--hyundai-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/hyundai_card-d9fc748b923387288cae15ec978741aca30f6d890bd6e9efc1075744cfadec5a.svg),
    none;
}

.payment-icon--ideal {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ideal-35160b934b25f7635f1bf94b7fbec57a1e3e44d946e811e6aba472e11142cbcd.svg),
    none;
}

.payment-icon--in3 {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/in3-30bfcc83b9ae84f980bc26830694a883dbccf3fb240f38f0c414a0291336fbe0.svg),
    none;
}

.payment-icon--indomaret {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/indomaret-eaa4aa194111743f3c1f0269d6de3fb4439c77951c9565ffcb8ca49b645b6df6.svg),
    none;
}

.payment-icon--ing-homepay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ing_homepay-a9db21bc556180f331d4afc657fcc4757863838d5c1193e0b931c77791c32910.svg),
    none;
}

.payment-icon--interac {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/interac-1d5902001e368e264bc690113c9c1784b6a91876b38e478644c57e181b7c44fa.svg),
    none;
}

.payment-icon--jcb {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/jcb-ab0f5a1739704f1ab039f19ac8c28895af5c39a3f54ee9b748ea051986b0bd36.svg),
    none;
}

.payment-icon--jousto {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/jousto-494917e43495f11028696231f6159579677d776b0dcc986755af1fd1e78870f2.svg),
    none;
}

.payment-icon--kakao-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kakao_pay-20e8010c7984aa5ffdfa5180a848bd83b4d3570b8b97e8e6893e5d7c0e76455e.svg),
    none;
}

.payment-icon--kasikornbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kasikornbank-f4e8e5bd96c7f37162dd6fc0daa4ae1ee2d334fe7c16b10ee2ff061e27fc45b4.svg),
    none;
}

.payment-icon--kb-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kb_card-580aa8e504583e1ced016482a78a4a2e85c9559572ea4c46b82b0fbb06c1f777.svg),
    none;
}

.payment-icon--kbc-cbc {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kbc_cbc-fa91cb17a1243415b94a9898bf019b0df9cf458f0780237551e4d36be21ed9bc.svg),
    none;
}

.payment-icon--kfast {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kfast-8e0df79ffe96a8d72512690930df8d152d34de864567a8b4679d90b449e8856c.svg),
    none;
}

.payment-icon--klarna-pay-later {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/klarna-pay-later-7c699d80da1c7d6efb9ddf7ba299166f88cf7fa615d667b06f45504b879e007a.svg),
    none;
}

.payment-icon--klarna-pay-now {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/klarna-pay-now-2516bae6e2a318cb44e4d29b920d93544d06e2a4b5ebcb985ab39202a68885c4.svg),
    none;
}

.payment-icon--klarna-slice-it {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/klarna-slice-it-6da293c1db517b9e895d4a5cf6e9a1621b942d7b71947788d38cacbbba5e0883.svg),
    none;
}

.payment-icon--klarna {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/klarna-389801c6056cb5600b4f05f72ebc2c58e4947688c6c4f5e6ccea41f7973d3a28.svg),
    none;
}

.payment-icon--knet {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/knet-f4b9f176f8dc7c901da07f395055708d95b072105e0f8ab4dfea5bdafe7fe8ea.svg),
    none;
}

.payment-icon--krediidipank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/krediidipank-51af4f784aecd8d3595e03999689f2c151d3f99d8ededb3c6fdc9dedc76d58a0.svg),
    none;
}

.payment-icon--krungsri {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/krungsri-90f4389d3583368f5db39fb1448e3f339df32c37ee829e673d85f2e52d2988c4.svg),
    none;
}

.payment-icon--krungthaibank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/krungthaibank-b6da77edc0cc0491e47d2adf1081c428470e4e87400f6a1ecfcf9ee6ceca86cd.svg),
    none;
}

.payment-icon--kueskipay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kueskipay-28c5525cd0be5a07c269770f4d62d4bb12fd2f9144e36320d24cc6e3ee7f90ea.svg),
    none;
}

.payment-icon--kunstencultuurcadeaukaart {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kunstencultuurcadeaukaart-a0a15f8232ecef79d5978b568c621a590be483f9cc596ec9d78af460d2a05f9e.svg),
    none;
}

.payment-icon--kuwaitfinancehouse {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/kuwaitfinancehouse-9712f4cb595916f1a0b8015cfc8b3ea7bce25af07f5d1ee7242b94c3d798b42c.svg),
    none;
}

.payment-icon--laser {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/laser-4e16350c611dc8bda5871718ba3b11010325ea8fba063529a64195e44e6242ec.svg),
    none;
}

.payment-icon--latitude-creditline-au {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/latitude_creditline_au-d214e9b8d10bdc14af0d2ce1263ce5c393905941f3e49b3559b11b853150123f.svg),
    none;
}

.payment-icon--latitude-gem-au {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/latitude_gem_au-ea391108081635ef3ddee6260c1555f09bb293504006b6549146b811e0bf84c4.svg),
    none;
}

.payment-icon--latitude-gem-nz {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/latitude_gem_nz-2c037c229b9399fccc2c8228780098bffb639973673bdb1c11dc654ceb6ce91c.svg),
    none;
}

.payment-icon--latitude-go-au {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/latitude_go_au-6c2f8feb616d4139fd77bd84ed2c8558126f2accefa87aa8508021173c9cd344.svg),
    none;
}

.payment-icon--lawson {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/lawson-854e3a0217fd26bc17d75fed4ae85cc6850c1ae274634482e9c073ec76a1fac2.svg),
    none;
}

.payment-icon--laybuy {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/laybuy-5c12ab49de1477417ebb0217414a0fd555695e1330be2ea7e3c6a691d980646b.svg),
    none;
}

.payment-icon--lhv {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/lhv-e179fe49401f7fa1c480fb60d48a50ec0865bee5bf48527f80a0068cbadf43ee.svg),
    none;
}

.payment-icon--line-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/line_pay-7893219d4244ee7f94ee1c43bd0e3481f51e259d75afb99fbc6a7985ce7fc26e.svg),
    none;
}

.payment-icon--linkaja {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/linkaja-4eaf09604aacb5d4edbaaf4160913460d5e13bc152a832c515e7128afc3ab4c4.svg),
    none;
}

.payment-icon--litecoin {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/litecoin-06f10bf73578fe346f5b2817673102b77c19ea71ebe05b2839495975651657c0.svg),
    none;
}

.payment-icon--lotte-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/lotte_card-b6ee125035961e5f87da72fd25964dcd8bed572fa688dc49ebfd4ef6d0426438.svg),
    none;
}

.payment-icon--luminor {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/luminor-a9f8c11f405c09bd69894ff82ff30a0cbd81961d8432fbf1b7fe940ac2d89b50.svg),
    none;
}

.payment-icon--mada {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mada-bd6d874050ad9db5e467d28bab17be1ab594a5ef19bfe7b560c94c89f28d5d82.svg),
    none;
}

.payment-icon--maestro {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/maestro-d2055c6b416c46cf134f393e1df6e0ba31722b623870f954afd392092207889c.svg),
    none;
}

.payment-icon--mandiri {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mandiri-440e15e1749827cece818f4693d184b7ca345ddba4e9665cb2b6ec1973e676a6.svg),
    none;
}

.payment-icon--mash {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mash-0a6d9c501d7d275196826d26eee8dd9ff803d45467422edd9cae1808f5c64e00.svg),
    none;
}

.payment-icon--master {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/master-173035bc8124581983d4efa50cf8626e8553c2b311353fbf67485f9c1a2b88d1.svg),
    none;
}

.payment-icon--masterpass {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/masterpass-3e0704a3dea59fa0b28c2686d2c3f53576c0318cce8a95d045570ebfa486463b.svg),
    none;
}

.payment-icon--maybank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/maybank-22e74e2f92a10c2ff4916a5cdb120cf42e2d413fe805bce9db50224bb8764865.svg),
    none;
}

.payment-icon--maybankm2u {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/maybankm2u-8089332e6416b7b972078a9754862982f31ae49111662b9f01320be48aa4bbcf.svg),
    none;
}

.payment-icon--maybankqrpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/maybankqrpay-7ca8a23f8e431aad22d242ba298282910739ea86032feeb84765c8066adcac5e.svg),
    none;
}

.payment-icon--mb {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mb-ab1ce2bc238edaf041ca8fb9c69f1fb967d7a1821986ae36372d7d4bc95c71ad.svg),
    none;
}

.payment-icon--mbway {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mbway-f4b555a024354de18eda3eb8da1952a4c9559412f71668a53cc8627777c1dd06.svg),
    none;
}

.payment-icon--mcash {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mcash-a1a328f8531933ab279eba7a9020878608525bf2fd9d9a28de69626e0642638c.svg),
    none;
}

.payment-icon--merpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/merpay-af26e4b3a3a6abf2dd7f617fc337de4642858e9d7d3452470014d9bde59cc489.svg),
    none;
}

.payment-icon--ministop {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ministop-6d5c82e75851ed7c87c7312616759b9592b173cf7758f25d4aea8eb378510c2f.svg),
    none;
}

.payment-icon--mobicred {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mobicred-009c9ba82342ee660ba640b27c6f3fd9b68c68685f16a51147a3459dc7c6fbd2.svg),
    none;
}

.payment-icon--mobikwik {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mobikwik-1badc6c3d0c5f99484fb2a2bd52090a88544a8949587162de411d9f288bf3f68.svg),
    none;
}

.payment-icon--mobilepay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mobilepay-8189c41b1722f6fcb30a138fbf543f2ea7c6eff901994c6dff22a31f432ab27e.svg),
    none;
}

.payment-icon--mondido {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mondido-29eb7451547140e47a5ea531d9693f8fa6f096bd581237f1e494a54940104529.svg),
    none;
}

.payment-icon--monero {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/monero-3e92299bb919a4479f86d9101549077493b22713ce9cb0077d7aa9c92b6d3d0d.svg),
    none;
}

.payment-icon--mpesa {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mpesa-642adcc0f93adebecd77ab0309f964bef70cce8a49b4bef1f97c427bdcd3452e.svg),
    none;
}

.payment-icon--mtn-mobile-money {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/mtn_mobile_money-91a7c72d332bc7c7b829b504351d26518c6c07717282127de99113cd86d6255d.svg),
    none;
}

.payment-icon--naps {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/naps-fec76bf699af63bf5973f7be15eea049ead313333d891195f9a021af8cfc14c0.svg),
    none;
}

.payment-icon--nationalebioscoopbon {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/nationalebioscoopbon-61a5002a0b429c52e2e5c6684f78ffce8386400048ce8bd77b9fd8c998694741.svg),
    none;
}

.payment-icon--nationaleentertainmentcard {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/nationaleentertainmentcard-26e9e1a563c1bd9a2f9ca97c2735cb3b8679ac757ce34b611747361262d7fce9.svg),
    none;
}

.payment-icon--naver-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/naver_pay-cd50ec57804aa1981c606f6882d36812f15516bc05fcb48213d39a84d3159cf4.svg),
    none;
}

.payment-icon--nelo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/nelo-517266c07bc5a19201306455ef74172a1da786e3a141e9889385350178c03d8b.svg),
    none;
}

.payment-icon--netbanking {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/netbanking-7fea52e535bcab5f2b1b2c9705c838756cf1c218a56cc4bcfd2ce0bf6383e295.svg),
    none;
}

.payment-icon--nh-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/nh_card-14aae201271659247194a5ecf71100860a95200980c8f2e3eb1822ba765cb418.svg),
    none;
}

.payment-icon--nordea {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/nordea-21b9557ba97b2c198f43eca2ed7b8a3ea2d8de2588b66b285d03a5896db170c5.svg),
    none;
}

.payment-icon--ocbcbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ocbcbank-1bb58190367bb005bea14cb0e548137547ee165db204cea330c1a2056cfdb28e.svg),
    none;
}

.payment-icon--ola-money {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ola_money-56c15887176a93ecce0a1737404effa4e659770e447fc270103a511d2f8458df.svg),
    none;
}

.payment-icon--op {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/op-2396f2c15692f6439ec9e2b556322a7707b90d64c7a2bda30478e6ab0163a94a.svg),
    none;
}

.payment-icon--ovo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ovo-1d8b7567e68067bacdff98d95fc11db27216459dfb82326f54be0eea128b31ec.svg),
    none;
}

.payment-icon--ozow {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ozow-476acd3ae0e23a6869e16141afcce99d04f9728d7da70aa1c45b8b5b0f4392aa.svg),
    none;
}

.payment-icon--pagoefectivo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/pagoefectivo-c892d3f05ab3a44016bc82743d28cb61bed48884c7b27becd0b47e642a57ab7c.svg),
    none;
}

.payment-icon--paidy {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paidy-64f66df75687b28c2e45a51dff2cbcf993b6a2d8ac5f597a4be2c68f2b7b7b01.svg),
    none;
}

.payment-icon--pay-easy {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/pay_easy-faf1f2460ddcc01b34069e26c6651e5c479acc6b2cf1a68afa1b17190fc984d1.svg),
    none;
}

.payment-icon--pay-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/pay_pay-1167872db2b6506aea25ac7248ddc9a56fd8db1934ea25ac56b13c75a40f3715.svg),
    none;
}

.payment-icon--payco {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payco-10dfb12bfc1b4f7b3eceffb4f366da169d3453728b286b1abb448a1df32df3b1.svg),
    none;
}

.payment-icon--payconiq {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payconiq-8a0213610a1bd7afc5f68e2932379155bc564ca8e6a0dece9934e83b523d592c.svg),
    none;
}

.payment-icon--payd {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payd-2c6c5c3a0d2f26535cc31e22d77ffe58afe4f0308779c80009f80420709d1e9b.svg),
    none;
}

.payment-icon--payfast-instant-eft {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payfast_instant_eft-51e3c7689a32673d7bb7f1ab97d7f9650ca9a50fc625c337a6d4e8d16dc25843.svg),
    none;
}

.payment-icon--payflex {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payflex-4cea3e8ed3e0df9f8389dd799ec1b1c4226a8d1ac73883cc66d7ac56e4ab5d5e.svg),
    none;
}

.payment-icon--paymark-online-eftpos {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paymark_online_eftpos-c24e05f814d7c3bbe1d75f9333f85557e7155ca6b921d024c173a356c7d60f8b.svg),
    none;
}

.payment-icon--paymaya {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paymaya-1f7921198f4e40465197a532fb1994d95f4e70f050796076698470a58c5469bd.svg),
    none;
}

.payment-icon--payme {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payme-534eded9f52cf4a14428409a809293b5b95475374c3f85c95e943590262a22b1.svg),
    none;
}

.payment-icon--paynow {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paynow-25a7173d016cec8ba3311eb8b9b55cd6e63a39f1b329f09e6116070c28bda123.svg),
    none;
}

.payment-icon--paypal {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paypal-49e4c1e03244b6d2de0d270ca0d22dd15da6e92cc7266e93eb43762df5aa355d.svg),
    none;
}

.payment-icon--paysafecard {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paysafecard-1bba93a00141d0964cef60e4fac4281320dd7af0ab5eff88068a05492aa6dd64.svg),
    none;
}

.payment-icon--paysera {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paysera-0a2dd811666f5f5db1bdfec1bfbe2e092fcff9a58337068ffaab53444862088b.svg),
    none;
}

.payment-icon--paytm {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paytm-ea638dba9d6e858f0f6c38d0b744ab791cabe2016309511ada28a5da51140909.svg),
    none;
}

.payment-icon--payu {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payu-6643a08ddf19a7cd6827aee69093e099d390c419f2f58f814c3c6d789e627404.svg),
    none;
}

.payment-icon--payzapp {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/payzapp-9276d25b935c69d0eb05b150d5112c4c8301c3e17898e8d4834edb8dfdc01dd3.svg),
    none;
}

.payment-icon--permata {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/permata-58df93ff19babf0f646c6d3dba4807356439473202fb370865483ea340ea3dfb.svg),
    none;
}

.payment-icon--pivo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/pivo-f02d762d0d71a1b957c6b203a82200c807642426061d331198073f760c62585a.svg),
    none;
}

.payment-icon--podiumcadeaukaart {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/podiumcadeaukaart-d1543a23abd63d4ac550bbb512e1ac8f0f7220c6f9a6af9ddce06bc51d2923d4.svg),
    none;
}

.payment-icon--postfinance-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/postfinance_card-d364f0a6a76c50ce6c40b3842b75681f89862ae1754feae9fd74e3cf4987775a.svg),
    none;
}

.payment-icon--postfinance-efinance {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/postfinance_efinance-5e6804d38e739fddfd9ff0827c7443d1e26a39d232a1b742cfc2f16ea8e3f80c.svg),
    none;
}

.payment-icon--prepaysolutions {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/prepaysolutions-6935a76ccaa76e48b82c1b0fbfe05f27cc7d92a291845bde7989e3be98ef8d98.svg),
    none;
}

.payment-icon--przelew24 {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/przelew24-eb718f0ae4c1ea7af91c7199814e13c25e512629a332448930a6612d99b40bc2.svg),
    none;
}

.payment-icon--publicbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/publicbank-4a3591d355f305e5988dfa25e39ad4eb4fa093fa8fa807317cc203aa1310dd08.svg),
    none;
}

.payment-icon--publicbank-pbe {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/publicbank_pbe-2f63f2f864c1128c4daec41f4215e9d169792c3d5847e49eec6b22886119d8e9.svg),
    none;
}

.payment-icon--qr-promptpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/qr_promptpay-cf7f26e8e96533260b7ae6f83445a88bfaddcac10f8837cf9ceb1c7b20df7a13.svg),
    none;
}

.payment-icon--qris {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/qris-76fe3044fb466ac59fdab12d40be2cc21a80513918e505a5e3761119fdefc755.svg),
    none;
}

.payment-icon--qrph {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/qrph-37b5ace41efba0dc21e7076e1275929baf3bf1627e60f69ab45ae2a7c6eb641b.svg),
    none;
}

.payment-icon--rabbitlinepay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/rabbitlinepay-cc46f46671a947c3d06841541169dc9a013f09935a0c544dfb53de4562eeff6d.svg),
    none;
}

.payment-icon--rakuten-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/rakuten_pay-82fef905123f7f378a1de754f338a2d4dea857b9bfd22da441db0710de010240.svg),
    none;
}

.payment-icon--ratepay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ratepay-67a2e1c2056a5fb641a9c76105978c238a3d6e70b192ad987b826bee9d21a4e5.svg),
    none;
}

.payment-icon--rcs {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/rcs-6f3a5d563ec080a8f8c221ce9ca92ea9e62f234ce415a8754016f279842afa3d.svg),
    none;
}

.payment-icon--rhbbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/rhbbank-9245be5f04d60a3c78db12299175be69a875e1c1e8321c3a31abdd4bd5c7aa34.svg),
    none;
}

.payment-icon--rhbnow {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/rhbnow-304d62d057284fcb0e6751397f9316222174383254d0b86d76133f6b744dc813.svg),
    none;
}

.payment-icon--rupay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/rupay-7dd8b2a3c63957a0174f9ad530376abbac88ed8baeb14d78e7887eec4208e63d.svg),
    none;
}

.payment-icon--sadad {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/sadad-7839efd8a2db51f29d0775717b0adc5fa7dd968ae85ad82a9f0ea9b030013d01.svg),
    none;
}

.payment-icon--sam {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/sam-3b1465b372c03ea2923625d86e77782528675d7065d2b5ad10aee2cd7aeabfd0.svg),
    none;
}

.payment-icon--samsung-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/samsung_card-928531ac9da3ec7be4b9d72f9a32e1064464a1de069a2d2ae43b156da0f80df9.svg),
    none;
}

.payment-icon--samsung-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/samsung_pay-6e800a720c23e0340aee7513d11e58c04fbecd5fe761c26c5d609ed777d4d0a8.svg),
    none;
}

.payment-icon--santander {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/santander-589d566d640572dfc3a5a2dfb15d145494157f417362e730868ec571e1a49319.svg),
    none;
}

.payment-icon--satispay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/satispay-08515d0358457f2fec6f90dfffda4a231259b5d7cd3319ccd14bf45f3b0f943c.svg),
    none;
}

.payment-icon--seb {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/seb-3ac27e0653ef348ee9ad2be6dc7abbc2e0ceb16fdb91203221cf0ad18e4ff9f7.svg),
    none;
}

.payment-icon--sepa-bank-transfer {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/sepa_bank_transfer-7de8159f39a7d31f4f32a8a2b3c0edceb91317a61750d0a9a13ea9e8adab812f.svg),
    none;
}

.payment-icon--seveneleven {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/seveneleven-39d6ebfea597ef8176d01c90fe567e2789b9db797d67d6abc214dbf6c01a80f4.svg),
    none;
}

.payment-icon--sezzle {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/sezzle-c41c5e28a60ef8af76601da54dd342364adc552c1d7d6e0efd11857d49056b5b.svg),
    none;
}

.payment-icon--shinhan-card {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/shinhan_card-9c6a454afb0842e929131f43581077456057425b56b2d59dfe4906b966155007.svg),
    none;
}

.payment-icon--shopeepay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/shopeepay-d29b8c9b04b6a62ee4e2c36c7575836737b1e85a0e1a01604d5361a77e9a00ff.svg),
    none;
}

.payment-icon--shopify-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/shopify_pay-100fde795157a3d1c18042346cf8dbd1fcf4c4f53c20064e13ea2799eb726655.svg),
    none;
}

.payment-icon--siamcommercial {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/siamcommercial-bbbad1ad1d0f4d780f7fd3f89b56609e240796980991a254231776d6a432cd98.svg),
    none;
}

.payment-icon--sofort {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/sofort-f51a776de54e6dcc46e0d6a565ff6e567030939549da222eda719c32ea581c25.svg),
    none;
}

.payment-icon--softbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/softbank-5366b6081887afd3fe9ad1ecf90194086688abac9ecd528cf8def49c541f5021.svg),
    none;
}

.payment-icon--splitit {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/splitit-8141f7c71723608dbcb25af9be108126f763ce78a28d9d6d5b15af56c85f5021.svg),
    none;
}

.payment-icon--spraypay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/spraypay-3fbed16a028f79050231046b347aee120e09fc898b08761ed4e8d7eb60f0dc18.svg),
    none;
}

.payment-icon--standardchartered {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/standardchartered-7e4e4f07b0c038105043e8de21ded6e0523e7a4c98e1dba94e13a8de2306159c.svg),
    none;
}

.payment-icon--stcpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/stcpay-093d20fbe864e7fbae502daed1368ac8af6182b7eb1b128993ed44ecdc90b510.svg),
    none;
}

.payment-icon--sunkus {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/sunkus-6476dd340cb3b742ea86c590efa7afb5cc3adb7ca97dfb334653423d35e8aee5.svg),
    none;
}

.payment-icon--swedbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/swedbank-810f6e65bc012b8060fabef3784b98dedf5195fa57ff4e8172735a2dd447eb85.svg),
    none;
}

.payment-icon--swish {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/swish-750b64719addcbfdfc23813169078b025599d8e017606c6321fc6c9f2c04e84a.svg),
    none;
}

.payment-icon--synchrony {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/synchrony-86925695affd6d79c1731dc2ac340f0cca6a04b68666e26d242eccfc8152c229.svg),
    none;
}

.payment-icon--tendopay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/tendopay-b9d6cdcc7cf02b4d7a8c77ea5c219f7cf7d6114acd17e17e176ca82784543ee9.svg),
    none;
}

.payment-icon--tescolotus {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/tescolotus-1eb6f6d13e078ea6bfb0a686af8892365b472cf6d1354adccfbbdf76b2e2cda2.svg),
    none;
}

.payment-icon--thanachartbank {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/thanachartbank-de2f24f8042e839a5e05efcd653ec4e44070a9b307f4442fa4a9dba5ec75c4f2.svg),
    none;
}

.payment-icon--toss {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/toss-8071fd2989acce986a6d7d49b9402d521c11184c6e924416935b18a6d7cdcd56.svg),
    none;
}

.payment-icon--touchngo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/touchngo-d5f99bacc613da1e0c2777c4e26b1ba9850ffe6ffa33e29187762115fc604f90.svg),
    none;
}

.payment-icon--truemoney-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/truemoney_pay-5954bc793247ba09cc6074dca4fb10b3b00bcbb2a3220f8e227b01fd44b8cdaa.svg),
    none;
}

.payment-icon--trustly {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/trustly-c8fe8255e4f01e38694aac39da40c930eb23d710c00ed19b4834837e497ac744.svg),
    none;
}

.payment-icon--twint {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/twint-805455506f9dfb7b0b7029a0a20e4e06503402099a7368134c8d5c786506cae3.svg),
    none;
}

.payment-icon--uaevisa {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/uaevisa-d7e64c19ae2260e532d14013d3eed193f732e6fffb5bc071c13eb6cf268fffd8.svg),
    none;
}

.payment-icon--ubp {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ubp-ebb50385c04d818b5a5cde136424c52b6de39834b7732dbdf5357c672f3fd34d.svg),
    none;
}

.payment-icon--unionpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/unionpay-8aedb760a6bde56bc2e3bbfd40bc008f1b0967a9f5863ecfbe855d54bde81ea6.svg),
    none;
}

.payment-icon--unipay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/unipay-60249e9da3927c24d1a6074598153940dd1f71eed38f2be31410c7edd1e66392.svg),
    none;
}

.payment-icon--uob {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/uob-e015c92721bafa7bfc248d5e5eac8266990a874320df497e67a5f197b309e8a9.svg),
    none;
}

.payment-icon--uobthai {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/uobthai-160cd4f516a56c10f1da06777962d603f61e212e0a89785d93d0c191d18e3979.svg),
    none;
}

.payment-icon--usdc {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/usdc-63db350e8676fc3cc704a1ec18c4159dcea077188db6df2a2f12d125b08f4653.svg),
    none;
}

.payment-icon--v-pay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/v_pay-cb37f954adf03e4355001daf0613c3257cae3fcd14b4e51eb4802248220c14c8.svg),
    none;
}

.payment-icon--venmo {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/venmo-e46496b4036966108664ea6a65bfeddd373275087df5f94cc3e5564fdb493eb0.svg),
    none;
}

.payment-icon--viabill {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/viabill-d7c752b7e5cabd7898f3762e601f2c038f3596ccd5e97d36d042984245ae3002.svg),
    none;
}

.payment-icon--vipps {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/vipps-728fbf0687f851629f7375f264ad1147cd28c0889c8a8aa14421221b8dd6f394.svg),
    none;
}

.payment-icon--visa {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/visa-319d545c6fd255c9aad5eeaad21fd6f7f7b4fdbdb1a35ce83b89cca12a187f00.svg),
    none;
}

.payment-icon--visaelectron {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/visaelectron-131aa567644ec0382c5762f24c751903c87a9ce4d2ffcfdb4be83be8e21d86b5.svg),
    none;
}

.payment-icon--vvv-giftcard {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/vvv_giftcard-842207b5684ddbcde3fa6775148f49e500fc50eb80e4fbeb10ab6c212bed4351.svg),
    none;
}

.payment-icon--webshopgiftcard {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/webshopgiftcard-c5ebfbf3344d4653d1abaf4fa6dc6f9feb83f9a24c53e8b572ac723b023d38dc.svg),
    none;
}

.payment-icon--wechatpay {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/wechatpay-dd2bf2476057628209c6b0142cd8cc7a4b5af17bd02af04fba67326a8838056f.svg),
    none;
}

.payment-icon--ymobile {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ymobile-1c54c59e750c79a00980914a141d58aab8f35e811190574895136bd8b56f9718.svg),
    none;
}

.payment-icon--zapper {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/zapper-b9587032b3d3c111782dcb3ebd143cbbc3299e0db7f377079f282c4f8cd00cda.svg),
    none;
}

.payment-icon--zip {
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/zip-a5964b1b2a3263022ee48635c50e1f87ce360cb9ec09de65f2cead8ec4410877.svg),
    none;
}

.payment-icon {
  border-radius: 0.2142857143em;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 2.7142857143em;
  height: 1.7142857143em;
}

.radio__label__accessory .payment-icon {
  cursor: default;
  margin: -0.1428571429em 0 -0.4285714286em;
}

.payment-icon--known {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.05);
}

.payment-icon--unknown {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}

.offsite-payment-gateway-logo {
  height: 24px;
  display: block;
  margin-top: -2px;
}

.payment-method-list__item + .payment-method-list__item {
  margin-top: 0.5em;
}

.payment-method-list__item-icon {
  vertical-align: middle;
  margin: -0.1em 0.25em 0 0;
}

.payment-method-list__item-link {
  white-space: nowrap;
}

.payment-icon-list__more {
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 0.25em;
  display: inline-block;
  cursor: default;
}

.wallet-card-summary-logo {
  display: inline-block;
  height: 1.28em;
  vertical-align: middle;
  width: auto;
}

.amazon-payments__widget {
  width: 100%;
  height: 400px;
}

.amazon-payments__link {
  display: block;
  margin-top: 1.5em;
}

.venmo__logo {
  width: 6.7857142857em;
  height: auto;
  margin: -2px;
  display: block;
}

.currency-selector__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.currency-selector__label {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 1em;
}

.currency-selector__amount {
  font-weight: 500;
}

.currency-selector__rate {
  font-size: 0.8571428571em;
  padding-top: 0.5em;
  color: #737373;
}

.currency-selector__change-currency-row {
  padding-top: 1em;
  font-size: 0.8571428571em;
}

.currency-selector__change-currency-link {
  cursor: pointer;
}

.blank-slate {
  padding: 1.1428571429em;
  text-align: center;
}

@media (min-width: 750px) {
  .blank-slate {
    padding-left: 4.5em;
    padding-right: 4.5em;
  }
}

.blank-slate__title {
  display: block;
  margin-bottom: 0.5714285714em;
}

.blank-slate__icon {
  margin-bottom: 1.1428571429em;
}

.blank-slate__btn {
  margin-top: 1.1428571429em;
}

.notice {
  position: relative;
  display: table;
  opacity: 1;
  margin-bottom: 1.4285714286em;
  padding: 1em;
  border-radius: 4px;
  border: 1px solid #d3e7f5;
  background-color: #eff8ff;
  color: #545454;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.step__sections + .notice {
  margin-top: 0.7142857143em;
}

.notice + .step__footer {
  margin-top: 0;
}

.notice .product-table {
  margin-top: 1.5em;
}

.notice .product-table td {
  border-color: #d3e7f5;
}

.notice .product-table tr:last-child td {
  padding-bottom: 0;
}

.notice__content {
  display: table-cell;
  width: 100%;
  padding-right: 1.1428571429em;
}

.notice__complementary {
  margin-top: 0.75em;
}

.notice__action {
  margin-top: 1.5em;
  border-color: #d3e7f5;
  color: #545454;
}

.notice__action:hover {
  background-color: rgba(211, 231, 245, 0.06);
  color: #545454;
}

.notice__action:focus,
.notice__action:active {
  border-color: #d3e7f5;
  -webkit-box-shadow: 0 0 0 1px #d3e7f5 inset;
  box-shadow: 0 0 0 1px #d3e7f5 inset;
}

.notice__icon {
  color: #1878b9;
  margin-top: -0.1428571429em;
  margin-right: 0.7142857143em;
}

.notice__controls {
  position: relative;
  padding: 1.1428571429em;
  margin: -1.1428571429em;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.notice__controls:hover {
  color: #333333;
}

.notice--success {
  border-color: #d1ecd4;
  background-color: #e9f8ec;
}

.notice--success .notice__icon {
  color: #0ca227;
}

.notice--success .notice__separator {
  border-color: #d1ecd4;
}

.notice--success .notice__action {
  border-color: #d1ecd4;
}

.notice--success .notice__action:hover {
  background-color: rgba(209, 236, 212, 0.06);
}

.notice--success .notice__action:focus,
.notice--success .notice__action:active {
  border-color: #d1ecd4;
  -webkit-box-shadow: 0 0 0 1px #d1ecd4 inset;
  box-shadow: 0 0 0 1px #d1ecd4 inset;
}

.product-table td {
  border-color: #d1ecd4;
}

.notice--warning {
  border-color: #f0e4bc;
  background-color: #fff8de;
}

.notice--warning .notice__icon {
  color: #b88600;
}

.notice--warning .notice__separator {
  border-color: #f0e4bc;
}

.notice--warning .notice__action {
  border-color: #f0e4bc;
}

.notice--warning .notice__action:hover {
  background-color: rgba(240, 228, 188, 0.06);
}

.notice--warning .notice__action:focus,
.notice--warning .notice__action:active {
  border-color: #f0e4bc;
  -webkit-box-shadow: 0 0 0 1px #f0e4bc inset;
  box-shadow: 0 0 0 1px #f0e4bc inset;
}

.product-table td {
  border-color: #f0e4bc;
}

.notice--error {
  border-color: #fad9d9;
  background-color: #ffebeb;
}

.notice--error .notice__icon {
  color: #e22120;
}

.notice--error .notice__separator {
  border-color: #fad9d9;
}

.notice--error .notice__action {
  border-color: #fad9d9;
}

.notice--error .notice__action:hover {
  background-color: rgba(250, 217, 217, 0.06);
}

.notice--error .notice__action:focus,
.notice--error .notice__action:active {
  border-color: #fad9d9;
  -webkit-box-shadow: 0 0 0 1px #fad9d9 inset;
  box-shadow: 0 0 0 1px #fad9d9 inset;
}

.product-table td {
  border-color: #fad9d9;
}

.notice--hide {
  opacity: 0;
}

.notice--collapsed .notice__complementary {
  display: none;
}

.notice--collapsed .icon-svg--rotate-180 {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.product td {
  padding-top: 1em;
}

.product:first-child td {
  padding-top: 0;
}

.product-thumbnail {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative;
}

.product-thumbnail::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  z-index: 2;
}

.product-thumbnail--small {
  width: 3em;
  height: 3em;
  border-radius: 6px;
}

.product-thumbnail--small::after {
  border-radius: 6px;
}

.product-thumbnail--small .product-thumbnail__wrapper {
  border-radius: 6px;
}

.product-thumbnail__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;
}

.product-thumbnail__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.product-thumbnail__quantity {
  font-size: 0.8571428571em;
  font-weight: 500;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114, 114, 114, 0.9);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5833333333em;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  z-index: 3;
}

.product__price {
  white-space: nowrap;
}

.product__description__name,
.product__description__variant,
.product__description__property {
  display: block;
}

.product__description {
  text-align: left;
  width: 100%;
}

.product__description .reduction-code {
  margin-top: 0.2857142857em;
}

.product__description .reduction-code__text {
  color: #717171;
}

.product__description__name {
  width: 1em;
  min-width: 100%;
}

.product__status {
  position: relative;
  white-space: nowrap;
}

.product__status--sold-out {
  color: #e32c2b;
}

.product__status__icon {
  vertical-align: -0.15em;
}

.product__clear-btn {
  position: absolute;
  right: -2.5em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 749px) {
  .product__clear-btn {
    display: none;
  }
}

.logged-in-customer-information {
  zoom: 1;
}

.logged-in-customer-information:after,
.logged-in-customer-information:before {
  content: "";
  display: table;
}

.logged-in-customer-information:after {
  clear: both;
}

.display-table .logged-in-customer-information {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.logged-in-customer-information__avatar-wrapper {
  padding-right: 1em;
  white-space: nowrap;
  vertical-align: middle;
}

.display-table .logged-in-customer-information__avatar-wrapper {
  display: table-cell;
}

.no-js .logged-in-customer-information__avatar-wrapper,
.no-display-table .logged-in-customer-information__avatar-wrapper {
  float: left;
}

.logged-in-customer-information__avatar {
  border-radius: 8px;
  background-size: cover;
  position: relative;
  max-width: none;
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.logged-in-customer-information__avatar:before {
  background-repeat: no-repeat;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/default-gravatar-f9ce6bb2ffed6561192882bdb60b7973f657618727eb4ad99742c55d6cecc33b.png);
  background-image: url(//cdn.shopify.com/shopifycloud/shopify/assets/checkout/default-gravatar-5ad673c45c717c5f83e82f5087d841e9cdf55e4b38458bb0b7272b8585dd6b4f.svg),
    none;
}

.logged-in-customer-information__paragraph {
  padding-top: 0.25em;
  vertical-align: middle;
}

.display-table .logged-in-customer-information__paragraph {
  display: table-cell;
  width: 100%;
}

.display-table .logged-in-customer-information__paragraph:only-child {
  display: block;
}

.no-js .logged-in-customer-information__paragraph,
.no-display-table .logged-in-customer-information__paragraph {
  float: left;
}

.logged-in-customer-newsletter {
  margin-top: 1.5em;
}

@media (min-width: 1000px) {
  .alt-payment-list-container {
    padding-top: 1.5em;
  }
}

@media (min-width: 1000px) {
  .alt-payment-list--center {
    text-align: center;
  }
}

.alt-payment-list {
  font-size: 0;
}

.alt-payment-list__item {
  position: relative;
  display: block;
  margin: 0 0 0.5em;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  vertical-align: middle;
}

@media (min-width: 750px) {
  .alt-payment-list__item {
    display: inline-block;
    margin: 0.5em 0 0.5em 0.5em;
  }

  .alt-payment-list__item:first-child {
    margin-left: 0;
  }
}

.alt-payment-list__item__link {
  display: block;
  padding: 1.2857142857em 0;
  min-width: 100%;
}

@media (min-width: 750px) {
  .alt-payment-list__item__link {
    padding: 0.8571428571em 0;
    min-width: 10.7142857143em;
  }
}

.alt-payment-list__item__logo {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 1.2857142857em;
}

.alt-payment-list__item--amazon {
  background: #fad676;
}

.alt-payment-list__item--amazon:hover {
  background-color: #f9ca4f;
}

.no-js .alt-payment-list__item--amazon {
  display: none;
}

.alt-payment-list__item--amazon .alt-payment-list__item__logo {
  -webkit-transform: translateY(0.2857142857em);
  transform: translateY(0.2857142857em);
  width: 6.5714285714em;
  height: 1.2857142857em;
}

.alt-payment-list-amazon-button-image {
  max-height: none !important;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.alt-payment-list__item--paypal {
  background-color: #ffc520;
}

.alt-payment-list__item--paypal:hover {
  background-color: #f6b600;
}

.alt-payment-list__item--paypalv4 .alt-payment-list__item__link {
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: top;
  line-height: 0 !important;
}

.alt-payment-list__item--paypalv4 .alt-payment-list__item--paypal-btn--mobile {
  display: none;
}

@media (max-width: 749px) {
  .alt-payment-list__item--paypalv4
    .alt-payment-list__item--paypal-btn--desktop {
    display: none;
  }

  .alt-payment-list__item--paypalv4
    .alt-payment-list__item--paypal-btn--mobile {
    display: block;
  }
}

.alt-payment-list__item--apple-pay {
  background-color: #000;
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  white-space: nowrap;
}

.alt-payment-list__item--apple-pay .alt-payment-list__item__link {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  color: #fff;
}

@media (min-width: 750px) {
  .alt-payment-list__item--apple-pay .alt-payment-list__item__link {
    padding: 0.8571428571em 0;
  }
}

.alt-payment-list__item--apple-pay
  .alt-payment-list__item__link
  .alt-payment-list__item__logo {
  width: 3em;
  background: -webkit-named-image(apple-pay-logo-white) center center no-repeat;
  background-size: auto 100%;
}

.google-pay-iframe-container {
  padding: 0;
}

.gpay-iframe {
  border-radius: 4px;
  height: 54px;
  width: 100%;
  display: block;
}

@media (min-width: 750px) {
  .gpay-iframe {
    height: 42px;
    width: 10.7142857143em;
  }
}

.alt-payment-list__item--google-pay {
  height: 54px;
  display: none;
}

@media (min-width: 750px) {
  .alt-payment-list__item--google-pay {
    height: 42px;
    width: 10.7142857143em;
  }
}

.alt-payment-list__item--google-pay-visible {
  display: block;
}

@media (min-width: 750px) {
  .alt-payment-list__item--google-pay-visible {
    display: inline-block;
  }
}

.alternative-payment-separator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 0.8571428571em;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #737373;
  margin-top: 2.5em;
}

@media (max-width: 749px) {
  .alternative-payment-separator {
    padding-bottom: 2em;
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .alternative-payment-separator {
    margin-bottom: 2em;
  }
}

.alternative-payment-separator::after,
.alternative-payment-separator::before {
  content: "";
  display: inline-block;
  height: 1px;
  background-color: #e6e6e6;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.alternative-payment-separator__content {
  display: inline-block;
  padding: 0 1em;
}

@media (min-width: 750px) {
  .dynamic-checkout {
    margin-top: 1.5em;
  }
}

.dynamic-checkout__title {
  color: #737373;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center;
}

.dynamic-checkout__title::before,
.dynamic-checkout__title::after {
  content: "";
  border: 1px #e6e6e6 solid;
  border-bottom: 0;
  height: 0.5em;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 2em;
  -ms-flex: 1 0 2em;
  flex: 1 0 2em;
}

.dynamic-checkout__title::before {
  border-right: 0;
  border-top-left-radius: 5px;
  margin-right: 1em;
}

.dynamic-checkout__title::after {
  border-left: 0;
  border-top-right-radius: 5px;
  margin-left: 1em;
}

.dynamic-checkout__content {
  border: 1px #e6e6e6 solid;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.9285714286em 1.4285714286em 1.4285714286em;
}

.dynamic-checkout__skeleton {
  display: none;
}

.dynamic-checkout__buttons {
  display: block;
}

.dynamic-checkout--loading .dynamic-checkout__skeleton {
  display: block;
}

.dynamic-checkout--loading .dynamic-checkout__skeleton .placeholder-line {
  height: 3.8571428571em;
}

@media (min-width: 750px) {
  .dynamic-checkout--loading .dynamic-checkout__skeleton {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .dynamic-checkout--loading .dynamic-checkout__skeleton .placeholder-line {
    max-width: 18.7142857143em;
    height: 3em;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .dynamic-checkout--loading
    .dynamic-checkout__skeleton
    .placeholder-line
    + .placeholder-line {
    margin-top: 0;
    margin-left: 0.5714285714em;
  }
}

.dynamic-checkout--loading .dynamic-checkout__buttons {
  display: none;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

@media (max-width: 749px) {
  .visually-hidden-on-mobile {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip: rect(0 0 0 0);
    width: 2px;
    height: 2px;
    margin: -2px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
  }
}

@media (min-width: 1000px) {
  .visually-hidden-on-desktop {
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip: rect(0 0 0 0);
    width: 2px;
    height: 2px;
    margin: -2px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
  }
}

.visible-on-focus {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.visible-on-focus:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.no-js .shown-if-js {
  display: none;
}

.js .hidden-if-js {
  display: none;
}

.js .visually-hidden-if-js {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

@media (max-width: 749px) {
  .hidden-on-mobile {
    display: none;
  }
}

@media (min-width: 750px) {
  .shown-on-mobile {
    display: none;
  }
}

.no-margin {
  margin: 0 !important;
}

@media (min-width: 750px) and (max-width: 999px) {
  .hidden-on-tablet {
    display: none;
  }
}

@media (max-width: 749px) and (min-width: 1000px) {
  .shown-on-tablet {
    display: none;
  }
}

@media (min-width: 1000px) {
  .hidden-on-desktop {
    display: none;
  }
}

@media (max-width: 999px) {
  .shown-on-desktop {
    display: none;
  }
}

@media (min-width: 750px) {
  .sms-marketing-fieldset {
    padding-left: 1.75em;
  }
}

.sms-marketing-disclaimer {
  margin-top: 0;
}

.order-summary-toggle {
  background: #fafafa;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 1.25em 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: left;
  width: 100%;
}

@media (min-width: 1000px) {
  .order-summary-toggle {
    display: none;
  }
}

.order-summary-toggle__inner {
  zoom: 1;
}

.order-summary-toggle__inner:after,
.order-summary-toggle__inner:before {
  content: "";
  display: table;
}

.order-summary-toggle__inner:after {
  clear: both;
}

.display-table .order-summary-toggle__inner {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.order-summary-toggle__icon-wrapper {
  vertical-align: middle;
  padding-right: 0.75em;
  white-space: nowrap;
}

.display-table .order-summary-toggle__icon-wrapper {
  display: table-cell;
}

.no-js .order-summary-toggle__icon-wrapper,
.no-display-table .order-summary-toggle__icon-wrapper {
  float: left;
}

.order-summary-toggle__icon {
  fill: #197bbd;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
}

.order-summary-toggle:hover .order-summary-toggle__icon,
.order-summary-toggle:focus .order-summary-toggle__icon {
  fill: #135e90;
}

.order-summary-toggle__text {
  color: #197bbd;
  vertical-align: middle;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  display: none;
}

.order-summary-toggle:hover .order-summary-toggle__text,
.order-summary-toggle:focus .order-summary-toggle__text {
  color: #135e90;
}

.order-summary-toggle--show .order-summary-toggle__text--show,
.order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: block;
}

.display-table .order-summary-toggle--show .order-summary-toggle__text--show,
.display-table .order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: table-cell;
  width: 100%;
}

.display-table
  .order-summary-toggle--show
  .order-summary-toggle__text--show:only-child,
.display-table
  .order-summary-toggle--hide
  .order-summary-toggle__text--hide:only-child {
  display: block;
}

.no-js .order-summary-toggle--show .order-summary-toggle__text--show,
.no-display-table .order-summary-toggle--show .order-summary-toggle__text--show,
.no-js .order-summary-toggle--hide .order-summary-toggle__text--hide,
.no-display-table
  .order-summary-toggle--hide
  .order-summary-toggle__text--hide {
  float: left;
}

.order-summary-toggle__total-recap {
  vertical-align: middle;
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap;
}

.display-table .order-summary-toggle__total-recap {
  display: table-cell;
}

.no-js .order-summary-toggle__total-recap,
.no-display-table .order-summary-toggle__total-recap {
  float: right;
}

.total-recap__original-price {
  font-size: 0.8571428571em;
  color: #737373;
  display: block;
}

.total-recap__final-price {
  font-size: 1.2857142857em;
  line-height: 1em;
  color: #333333;
}

.order-summary-toggle__dropdown {
  vertical-align: middle;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
  fill: #197bbd;
}

.order-summary-toggle:hover .order-summary-toggle__dropdown,
.order-summary-toggle:focus .order-summary-toggle__dropdown {
  fill: #135e90;
}

.g-recaptcha {
  border-radius: 4px;
  display: inline-block;
}

.field--error .g-recaptcha {
  border-color: #e32c2b;
  -webkit-box-shadow: 0 0 0 1px #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b;
}

.no-js .g-recaptcha {
  display: none;
}

.g-recaptcha-nojs {
  max-width: 100%;
  width: 302px;
}

.g-recaptcha-nojs__iframe {
  height: 423px;
  width: 100%;
}

.g-recaptcha-nojs__input-wrapper {
  background: #f9f9f9;
  border: 1px #c1c1c1 solid;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0.5em;
  padding: 0.75em;
}

.g-recaptcha-nojs__input {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 5em;
  padding: 0.4285714286em;
  resize: vertical;
  width: 100%;
}

.field--error .g-recaptcha-nojs__input {
  border-color: #e32c2b;
  -webkit-box-shadow: 0 0 0 1px #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b;
}

.sp-modal__button-wrapper {
  position: relative;
  overflow: hidden;
  padding: 1px;
}

.sp-modal-toggle {
  opacity: 0;
  bottom: 0;
  border-radius: 0 5px 5px 0;
  margin: 1px;
  padding: 0 1em;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 1;
  -webkit-animation: sp-fade-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1);
  animation: sp-fade-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1);
}

@media (max-width: 749px) {
  .sp-modal-toggle {
    -webkit-animation-name: fade-in;
    animation-name: fade-in;
  }
}

.sp-modal-toggle:hover .sp-modal-toggle__phone-wrapper::after {
  border-color: #135e90;
}

.sp-modal-toggle:focus {
  outline-offset: -3px;
  outline-color: -webkit-focus-ring-color;
  outline-width: 4px;
}

.sp-modal-toggle__icon-wrapper {
  display: block;
  position: relative;
}

.sp-modal-toggle__icon-wrapper .sp-modal-toggle__icon {
  -webkit-animation: wobble 100ms 250ms linear 6;
  animation: wobble 100ms 250ms linear 6;
}

.sp-modal-toggle__icon-wrapper::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 8px;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border: 1px solid #197bbd;
  border-radius: 50%;
  -webkit-animation: scale-up-bounce 400ms 850ms both;
  animation: scale-up-bounce 400ms 850ms both;
}

.has-sp-modal body {
  height: auto;
  position: relative;
  min-height: 100%;
}

.sp-modal-backdrop {
  background-color: rgba(0, 0, 0, 0);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1),
    background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1),
    background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
  z-index: 999;
  overflow-y: scroll;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sp-modal-backdrop--visible {
  background-color: rgba(0, 0, 0, 0.18);
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sp-modal {
  color: #545454;
  opacity: 0;
  outline: none;
  position: absolute;
  -webkit-transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  -webkit-transform-origin: 0% 15%;
  transform-origin: 0% 15%;
  -webkit-transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
}

@media (max-width: 749px) {
  .sp-modal {
    left: 50%;
    top: 2em;
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
    transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  }
}

.sp-modal-backdrop--visible .sp-modal {
  opacity: 1;
  -webkit-transform: scale(1)
    matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible;
}

@media (max-width: 749px) {
  .sp-modal-backdrop--visible .sp-modal {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%);
  }
}

.sp-modal--hidden .sp-modal {
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
}

@media (max-width: 749px) {
  .sp-modal--hidden .sp-modal {
    -webkit-transform: scale(1) translateX(-50%);
    transform: scale(1) translateX(-50%);
  }
}

.sp-modal::before {
  background: white;
  border-radius: 4px;
  content: "";
  display: block;
  height: 25px;
  left: 2px;
  margin-left: -8px;
  position: absolute;
  top: 1.8em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px;
}

@media (max-width: 749px) {
  .sp-modal::before {
    display: none;
  }
}

.sp-modal::after {
  -webkit-box-shadow: 0 19px 47px rgba(3, 5, 7, 0.35);
  box-shadow: 0 19px 47px rgba(3, 5, 7, 0.35);
  content: "";
  display: block;
  height: 100%;
  left: 5%;
  position: absolute;
  right: 5%;
  top: 0;
  width: 90%;
  z-index: -1;
}

.sp-modal__frame {
  -webkit-transition: height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  max-width: initial;
}

.sp-modal--top {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.sp-modal--top::before {
  left: 50%;
  bottom: -8px;
  margin-left: -12.5px;
  top: auto;
}

.sp-modal--center {
  left: 50%;
  top: 50%;
  position: fixed;
}

.sp-modal-backdrop--visible .sp-modal--center {
  -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: 0;
}

.sp-modal--center::before {
  display: none;
}

.sp-modal--bottom-right,
.sp-modal--bottom-left {
  -webkit-transform-origin: 95% 0%;
  transform-origin: 95% 0%;
}

.sp-modal--bottom-right::before,
.sp-modal--bottom-left::before {
  left: auto;
  margin-left: -8px;
  right: 7px;
  top: -6px;
}

.sp-modal--bottom-right {
  -webkit-transform-origin: 5% 0%;
  transform-origin: 5% 0%;
}

.sp-modal__content {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.sp-modal__close {
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  height: 1em;
  width: 1em;
  color: #737373;
  -webkit-transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
}

.sp-modal__close:focus,
.sp-modal__close:hover,
.sp-modal__close:active {
  color: #545454;
}

@-webkit-keyframes sp-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0.5em);
    transform: translateX(0.5em);
    visibility: hidden;
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes sp-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0.5em);
    transform: translateX(0.5em);
    visibility: hidden;
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
  }
}

@-webkit-keyframes skeletonShimmerAnimation {
  0% {
    opacity: 0.45;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes skeletonShimmerAnimation {
  0% {
    opacity: 0.45;
  }

  100% {
    opacity: 0.9;
  }
}

.content .skeleton-loading-text,
.order-summary-toggle .skeleton-loading-text {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(113, 113, 113, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  width: 5em;
  height: 1.3em;
}

.content .skeleton-loading-text--lg,
.order-summary-toggle .skeleton-loading-text--lg {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(113, 113, 113, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.7142857143em;
  width: 8em;
}

.content .skeleton-loading-text--sm,
.order-summary-toggle .skeleton-loading-text--sm {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(113, 113, 113, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 0.8571428571em;
  width: 5em;
}

.content .skeleton-loading-text--left,
.order-summary-toggle .skeleton-loading-text--left {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(113, 113, 113, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.3em;
  width: 5em;
  float: left;
}

.content .skeleton-loading-text--rate,
.order-summary-toggle .skeleton-loading-text--rate {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(113, 113, 113, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.45em;
  width: 12em;
  float: none;
}

.content .skeleton-loading-text--link,
.order-summary-toggle .skeleton-loading-text--link {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(113, 113, 113, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.5em;
  width: 8em;
  float: none;
}

.content .skeleton-loading-text--inline,
.order-summary-toggle .skeleton-loading-text--inline {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(113, 113, 113, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.3em;
  width: 5em;
  position: absolute;
  margin-left: 0.25em;
  display: inline-block;
}

.disable-link-while-loading.loading {
  pointer-events: none;
  color: #717171;
}

.review-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.review-block ~ .review-block {
  margin-top: 0.8571428571em;
  padding-top: 0.8571428571em;
  border-top: 1px solid #e6e6e6;
}

.review-block__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 749px) {
  .review-block__inner {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.review-block__label {
  color: #737373;
  padding-right: 1.1428571429em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 5em;
  -ms-flex: 0 1 5em;
  flex: 0 1 5em;
}

@media (max-width: 749px) {
  .review-block__label {
    padding-bottom: 0.2857142857em;
  }
}

.review-block__content {
  -webkit-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;
  color: #333333;
  padding-right: 1.1428571429em;
}

@media (max-width: 749px) {
  .review-block__content {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}

.review-block__link {
  max-width: 10em;
  font-size: 0.8571428571em;
}

.review-block__billing-address {
  margin-top: 0.25em;
}

.flag-selector {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  -webkit-transition: all 0.2s cubic-bezier(0, 0.34, 0.1, 0.98);
  transition: all 0.2s cubic-bezier(0, 0.34, 0.1, 0.98);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 0.7142857143rem;
}

.no-js .flag-selector,
.no-anyflexbox .flag-selector {
  display: table;
}

.flag-selector::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
  height: 20px;
  border-left: 1px solid transparent;
}

.flag-selector--visible::after {
  border-left-color: #d9d9d9;
  -webkit-animation: scale-up 0.1s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: scale-up 0.1s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
}

.flag-selector--hidden {
  display: none;
}

.flag-selector--focus {
  background: rgba(25, 123, 189, 0.25);
}

.flag-selector--focus::after {
  display: none;
}

.flag-selector__caret {
  display: block;
  margin-left: 0.5em;
}

.no-js .flag-selector__caret,
.no-anyflexbox .flag-selector__caret {
  display: table-cell;
  vertical-align: middle;
}

.flag-selector--visible .flag-selector__caret {
  -webkit-animation: fade-in 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: fade-in 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
}

.flag-selector--focus .flag-selector__caret {
  fill: #197bbd;
}

.flag-selector__icon {
  height: 20px;
  width: 27px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-filter: saturate(1.3) hue-rotate(-5deg);
  filter: saturate(1.3) hue-rotate(-5deg);
  opacity: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.no-js .flag-selector__icon,
.no-anyflexbox .flag-selector__icon {
  display: table-cell;
  vertical-align: middle;
}

.flag-selector--visible .flag-selector__icon {
  -webkit-animation: scale-up-bounce 0.2s cubic-bezier(0, 0.34, 0.1, 0.98)
    forwards;
  animation: scale-up-bounce 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
}

.flag-selector--hidden .flag-selector__icon {
  -webkit-animation: scale-down-bounce 0.2s cubic-bezier(0.57, 0.31, 0.42, 0.91)
    forwards;
  animation: scale-down-bounce 0.2s cubic-bezier(0.57, 0.31, 0.42, 0.91)
    forwards;
}

.flag-selector__select {
  background-color: inherit;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.placeholder-line {
  height: 1em;
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #e6e6e6;
}

.placeholder-line + .placeholder-line {
  margin-top: 0.5em;
}

.placeholder-line--animated {
  -webkit-animation: 1.5s linear 0s infinite normal forwards running loading;
  animation: 1.5s linear 0s infinite normal forwards running loading;
  background-repeat: no-repeat;
  background-size: 200px 100%;
}

.main .placeholder-line--animated {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#e6e6e6),
    color-stop(50%, #cdcdcd),
    to(#e6e6e6)
  );
  background-image: linear-gradient(
    to left,
    #e6e6e6 0%,
    #cdcdcd 50%,
    #e6e6e6 100%
  );
}

.sidebar .placeholder-line--animated {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#e1e1e1),
    color-stop(50%, #c8c8c8),
    to(#e1e1e1)
  );
  background-image: linear-gradient(
    to left,
    #e1e1e1 0%,
    #c8c8c8 50%,
    #e1e1e1 100%
  );
}

.content-box .placeholder-line--animated {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#e6e6e6),
    color-stop(50%, #cdcdcd),
    to(#e6e6e6)
  );
  background-image: linear-gradient(
    to left,
    #e6e6e6 0%,
    #cdcdcd 50%,
    #e6e6e6 100%
  );
}

.default-background .placeholder-line--animated {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#e6e6e6),
    color-stop(50%, #cdcdcd),
    to(#e6e6e6)
  );
  background-image: linear-gradient(
    to left,
    #e6e6e6 0%,
    #cdcdcd 50%,
    #e6e6e6 100%
  );
}

.placeholder-line--loose-vertical + .placeholder-line--loose-vertical {
  margin-top: 1em;
}

.placeholder-line--40 {
  width: 40%;
}

.placeholder-line--45 {
  width: 45%;
}

.placeholder-line--50 {
  width: 50%;
}

.placeholder-line--60 {
  width: 60%;
}

.placeholder-line--70 {
  width: 70%;
}

.placeholder-line--75 {
  width: 75%;
}

.placeholder-line--90 {
  width: 90%;
}

.placeholder-line--100 {
  width: 100%;
}

@-webkit-keyframes loading {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(100% + 200px) 0;
  }
}

@keyframes loading {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(100% + 200px) 0;
  }
}

.skip-to-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  margin: -1px;
}

.skip-to-content:focus {
  margin: 0;
  padding: 1em;
  clip: auto;
  width: 100%;
  height: auto;
  overflow: visible;
}

.tags-list {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tags-list .tag {
  margin-top: 0.8571428571em;
  margin-right: 0.8571428571em;
}

.tags-list .tag:last-child {
  margin-right: 0;
}

@media (min-width: 1000px) {
  .tags-list {
    float: left;
  }
}

.tag {
  border-radius: 4px;
  background-color: rgba(113, 113, 113, 0.11);
  color: #717171;
  font-size: 0.8571428571em;
  padding: 0.8571428571em;
  overflow: hidden;
}

.tag__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.tag__text {
  color: #323232;
  font-size: 1.1428571429em;
  font-weight: 500;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag__button {
  margin-left: 0.8571428571em;
}

.tag__button .icon-svg {
  stroke: rgba(113, 113, 113, 0.9);
}

.tag__button:hover .icon-svg,
.tag__button:focus .icon-svg {
  stroke: #323232;
}

.list {
  padding-left: 1.5em;
  list-style: disc outside;
  line-height: 1.5em;
}

.list + .list {
  margin-top: 1.5em;
}

.list__item > .list {
  margin-top: 0.5em;
}

.list__item {
  margin-top: 0.5em;
}

.list__item:first-child {
  margin-top: 0;
}

.tabs__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tabs__item {
  width: 100%;
}

.tabs__button {
  width: 100%;
  height: 100%;
  padding: 1.1428571429em;
  -webkit-box-shadow: inset 0 -3px 0 0 #d9d9d9;
  box-shadow: inset 0 -3px 0 0 #d9d9d9;
  color: #737373;
  font-weight: 500;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tabs__button:hover {
  -webkit-box-shadow: inset 0 -3px 0 0 #919191;
  box-shadow: inset 0 -3px 0 0 #919191;
  color: #545454;
}

.tabs__button > * {
  pointer-events: none;
}

.tabs__button--active,
.tabs__button--active:hover {
  -webkit-box-shadow: inset 0 -3px 0 0 #197bbd;
  box-shadow: inset 0 -3px 0 0 #197bbd;
  color: #197bbd;
}

.button-group__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.button-group__list .button-group__item:first-of-type .button-group__button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button-group__list .button-group__item:last-of-type .button-group__button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.button-group__list--bordered .button-group__item {
  position: relative;
}

.button-group__list--bordered .button-group__item:not(:first-of-type) {
  border-left: 1px #d9d9d9 solid;
}

.button-group__item {
  width: 100%;
}

.button-group__button {
  width: 100%;
  height: 100%;
  padding: 1.1428571429em;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #545454;
  font-weight: 500;
  -webkit-transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-color: transparent;
}

.button-group__button > * {
  pointer-events: none;
}

.button-group__button--active {
  color: #197bbd;
  border-style: solid;
  border-color: #197bbd;
  border-width: 2px;
}

.button-group__button--subtitled {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.4em;
}

.has-qr-modal body {
  height: auto;
  position: relative;
  min-height: 100%;
}

.qr-modal-backdrop {
  background-color: rgba(0, 0, 0, 0);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1),
    background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1),
    background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
  z-index: 999;
  overflow: visible;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.qr-modal-backdrop--visible {
  background-color: rgba(0, 0, 0, 0.18);
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.qr-modal {
  color: #545454;
  opacity: 0;
  outline: none;
  position: absolute;
  -webkit-transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  -webkit-transform-origin: 0% 15%;
  transform-origin: 0% 15%;
  -webkit-transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1),
    -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
}

.qr-modal-backdrop--visible .qr-modal {
  opacity: 1;
  -webkit-transform: scale(1)
    matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible;
}

.qr-modal--hidden .qr-modal {
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1)
    matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
}

.qr-modal::before {
  background: #dddddd;
  background: linear-gradient(45deg, white 0%, #ddd 50%);
  border-radius: 4px;
  content: "";
  display: block;
  height: 25px;
  left: 2px;
  margin-left: -8px;
  position: absolute;
  top: 1.8em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px;
}

.qr-modal::after {
  -webkit-box-shadow: 0 19px 47px rgba(3, 5, 7, 0.35);
  box-shadow: 0 19px 47px rgba(3, 5, 7, 0.35);
  content: "";
  display: block;
  height: 100%;
  left: 5%;
  position: absolute;
  right: 5%;
  top: 0;
  width: 90%;
  z-index: -1;
}

.qr-modal--top {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.qr-modal--top::before {
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: 50%;
  bottom: -8px;
  margin-left: -12.5px;
  top: auto;
}

.qr-modal--center {
  left: 50%;
  top: 50%;
  position: fixed;
}

.qr-modal-backdrop--visible .qr-modal--center {
  -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: 0;
}

.qr-modal--center::before {
  display: none;
}

.qr-modal--bottom-right,
.qr-modal--bottom-left {
  -webkit-transform-origin: 95% 0%;
  transform-origin: 95% 0%;
}

.qr-modal--bottom-right::before,
.qr-modal--bottom-left::before {
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: auto;
  margin-left: -8px;
  right: 7px;
  top: -6px;
}

.qr-modal--bottom-right {
  -webkit-transform-origin: 5% 0%;
  transform-origin: 5% 0%;
}

.qr-modal__content {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.qr-code-container {
  max-width: 14.2857142857em;
  font-size: 1em;
}

.qr-code-section {
  padding: 1.1428571429em;
  font-size: 1em;
  line-height: 1.3em;
  color: #000;
}

.qr-code-separator {
  margin: 0;
  border-top: none;
  color: #9b9b9b;
}

.qr-code-title {
  margin-top: 1.1428571429em;
  font-size: 1em;
  font-weight: 500;
}

.shopUpsellBtn__wrapper {
  position: relative;
  display: inline-block;
}

.shopQrWithSmsPopover__wrapper {
  display: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 372px;
  border-radius: 10px;
  background: #fff;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 9999;
  left: 100%;
  margin-left: 23px;
  top: 0;
  padding: 20px 17px 11px;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.shopQrWithSmsPopover__wrapper::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  top: 15px;
  left: -7px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #fff;
}

.shopQrWithSmsPopover__wrapper::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  top: 15px;
  left: -8px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: rgba(0, 0, 0, 0.1);
  z-index: -1px;
}

.shopQrWithSmsPopover__wrapper--show {
  display: block;
}

.shopQrWithSmsPopover__wrapper--animate {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  pointer-events: initial;
}

.shopQrWithSmsPopover__qrSection {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.6);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.shopQrWithSmsPopover__qrBox {
  height: 156px;
  min-width: 156px;
  margin-right: 1.1428571429em;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.shopQrWithSmsPopover__qrBox iframe {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.shopQrWithSmsPopover__qrLogoBox {
  background: #fff;
  border-radius: 10px;
  width: 52px;
  height: 52px;
  display: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.shopQrWithSmsPopover__qrLogoBox svg {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.shopQrWithSmsPopover__qrLogoBox.show {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.shopQrWithSmsPopover__qrUpsellTitle {
  margin-bottom: 1.1428571429em;
}

.shopQrWithSmsPopover__smsUpsellTitle {
  margin-bottom: 1.1428571429em;
}

.shopQrWithSmsPopover__smsSection {
  display: none;
}

.shopQrWithSmsPopover__smsSection.show {
  display: block;
}

.shopQrWithSmsPopover__smsSectionToggleHandler {
  display: none;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 6px;
  cursor: pointer;
  color: #197bbd;
}

.shopQrWithSmsPopover__smsSectionToggleHandler.show {
  display: block;
}

.mandates--align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.mandates--content > :not(:first-child) {
  margin-left: 0.75em;
}

.mandates--card-details {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.mandates--expired {
  background-color: #ffebeb;
  border-radius: 2rem;
  color: #e32c2b;
  padding: 0.1em 0.6em;
}

.mandates--checkbox {
  margin: 0 1.1428571429em 1.1428571429em;
}

.mandates--info {
  margin-left: 2em;
}

.mandates--cvv-verification {
  margin-top: 1.5em;
}

.billing-address-mandate--info {
  font-weight: normal;
  margin-top: 0.25em;
}

.customer-mandate-modal--body {
  padding-bottom: 1em;
}

.customer-mandate-modal--buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-column-gap: 0.8em;
  -moz-column-gap: 0.8em;
  column-gap: 0.8em;
}

.customer-mandate-modal--cancel {
  color: #202223;
}

.customer-mandate-modal--cancel:hover {
  background-color: #f6f6f7;
  color: #202223;
}

.customer-mandate-modal--remove {
  background-color: #d82c0d;
  border-color: rgba(0, 0, 0, 0);
  color: white;
}

.customer-mandate-modal--remove:hover {
  background-color: #bc2200;
}

.maximum-vaulted-cards--info {
  line-height: 1.5em;
}

#credit-card-vaulting-sign-in-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#credit-card-vaulting-sign-in-banner .notice__info {
  margin-left: 0.2142857143em;
}

.page--stock-problems.page--logo-main .breadcrumb,
.page--stock-problems.page--logo-sidebar .main__header,
.page--stock-problems.page--logo-banner .main__header {
  display: none;
}

.stock-problem-table {
  border-bottom: 1px solid #e6e6e6;
}

.exclamation-mark {
  color: #737373;
  fill: currentColor;
  stroke: currentColor;
}

.exclamation-mark__circle {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
}

.cssanimations .exclamation-mark__circle {
  stroke-dasharray: 151;
  stroke-dashoffset: 151;
  -webkit-animation: draw-stroke 0.6s 0.4s cubic-bezier(0.3, 0, 0, 1) forwards;
  animation: draw-stroke 0.6s 0.4s cubic-bezier(0.3, 0, 0, 1) forwards;
}

.cssanimations .exclamation-mark__line {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  -webkit-animation: draw-stroke 0.3s 0.8s cubic-bezier(0.3, 0, 0, 1) forwards;
  animation: draw-stroke 0.3s 0.8s cubic-bezier(0.3, 0, 0, 1) forwards;
}

.exclamation-mark__dot {
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
}

.cssanimations .exclamation-mark__dot {
  -webkit-animation: scale-up 0.3s 1.01s cubic-bezier(0.3, 0, 0, 1) both;
  animation: scale-up 0.3s 1.01s cubic-bezier(0.3, 0, 0, 1) both;
}

.os-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

@media (min-width: 750px) {
  .os-header {
    margin: 0 0 -0.5em;
  }
}

.os-header__heading {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.os-order-number {
  display: block;
  margin-bottom: 0.25em;
}

.os-header__title {
  font-size: 1.5714285714em;
}

.os-header__hanging-icon {
  margin-right: 0.75em;
  stroke: #197bbd;
}

@media (min-width: 750px) and (max-width: 999px) {
  .os-header__hanging-icon {
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-right: 1.5em;
  }
}

@media (min-width: 1300px) {
  .os-header__hanging-icon {
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-right: 1.5em;
  }
}

.map {
  overflow: hidden;
}

.content-box__row:first-child .map {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.map__iframe {
  display: block;
  height: 200px;
  width: calc(100% + 1px);
  border: 0;
  z-index: 0;
}

.map__placeholder {
  height: 100%;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.pickup_point_map__cluster {
  height: 36px !important;
  width: 36px !important;
  border-radius: 50%;
  background: #333333;
  border: 2px solid #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
}

.gm-style .gm-style-iw {
  color: #333333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 400;
  padding-top: 5px;
  text-align: center;
  width: 100%;
}

.gm-style .gm-style-iw + div {
  display: none;
}

.os-timeline {
  margin: 0;
  padding: 0;
}

.os-timeline-step__title {
  display: block;
  color: #737373;
}

.os-timeline-step__date {
  display: none;
}

.os-timeline-step--selected .os-timeline-step__icon {
  fill: #197bbd;
}

.os-timeline-step--selected .os-timeline-step__title {
  color: #197bbd;
  font-weight: 500;
}

.os-timeline-step--selected .os-timeline-step__date {
  display: block;
  font-size: 0.8571428571em;
  color: #737373;
}

@media (max-width: 749px) {
  .os-timeline-step:not(.os-timeline-step--current) {
    display: none;
  }

  .os-timeline-step {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .os-timeline-step__title {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@media (min-width: 750px) {
  .os-timeline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .os-timeline-step {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    max-width: 25%;
    position: relative;
    padding-top: 20px;
  }

  .os-timeline-step:before,
  .os-timeline-step:after {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 8.5px;
    right: 50%;
  }

  .os-timeline-step:before {
    background: #e6e6e6;
    width: 2000px;
  }

  .os-timeline-step:first-child:after {
    background: #fff;
    left: 0;
    z-index: 2;
  }

  .os-timeline-step__icon {
    fill: #b3b3b3;
    background: #fff;
    background: #fff;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 3;
  }

  .os-timeline-step__title {
    display: block;
    color: #737373;
  }

  .os-timeline-step__date {
    display: none;
  }

  .os-timeline-step--selected:before {
    background: #197bbd;
    z-index: 1;
  }

  .os-timeline-step--selected .os-timeline-step__icon {
    fill: #197bbd;
  }

  .os-timeline-step--selected .os-timeline-step__title {
    color: #197bbd;
    font-weight: 500;
  }

  .os-timeline-step--selected .os-timeline-step__date {
    display: block;
  }
}

.os-step__info {
  margin-top: 1em;
}

.os-step__info-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.75em;
}

.os-step__special-description + .os-step__special-description {
  border-top: 1px #e6e6e6 solid;
  margin-top: 1.5em;
  padding-top: 1.5em;
}

.os-step__shop-logo {
  width: 36px;
}

.os-step__shop-pay-disclaimer {
  margin-bottom: 12px;
}

.os-step__shop-pay-disclaimer-icon {
  width: 58px;
  height: 14px;
}

.os-step__shop-pay-disclaimer-icon path {
  fill: #000;
}

.tracking-info {
  border-top: 1px solid #e6e6e6;
  margin-top: 1.1428571429em;
  padding-top: 1.1428571429em;
}

.tracking-info__number {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  color: #737373;
}

.shipment-information {
  padding: 1em;
}

.shipment-information__items td {
  padding-top: 1em;
}

.shipment-information__item:first-child td {
  padding-top: 0;
}

.shipment-information__image {
  width: 2.5em;
}

.shipment-information__image .product-thumbnail {
  width: 2.5em;
  height: 2.5em;
  border-right: 6px;
}

.shipment-information__image .product-thumbnail__wrapper {
  border-right: 6px;
}

.shipment-information__image .product-thumbnail__quantity {
  font-size: 11px;
  top: -0.65em;
  padding: 0 0.55em;
}

.arrive-phone-description {
  margin: 8px 0 6px;
  color: #545454;
  font-size: 0.85714em;
  line-height: 135%;
}

.arrive-button {
  font-weight: 600;
}

.arrive-button__regular {
  font-weight: 400;
}

.arrive-button__logo {
  width: 35px;
  height: 14px;
}
