@media (min-width: 1000px) {
  .main .logo {
    display: inherit;
  }

  .wrap {
    display: flex;
    align-items: stretch;
    padding: 0 5%;
    width: 90%;
    max-width: 92.5714285714em;
    min-height: 100vh;
  }

  .main {
    width: 52%;
    display: flex;
    flex: auto;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar {
    width: 49%;
    padding-top: 4em;
  }
}

@media (min-width: 750px) {
  .main {
    padding-top: 4em;
  }
  .field--half {
    width: 50%;
  }

  .field--third {
    width: 33.3333333333%;
  }
}

.order-summary-toggle__inner {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.order-summary-toggle__icon-wrapper {
  display: table-cell;
}

.order-summary-toggle--show .order-summary-toggle__text--show,
.order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: table-cell;
  width: 100%;
}

.order-summary-toggle__total-recap {
  display: table-cell;
}

.field {
  margin-bottom: 0 !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 14px;
}
