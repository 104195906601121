.small-dynamic-dialog {
  width: 25vw;
}

.medium-dynamic-dialog {
  width: 42vw;
}

.large-dynamic-dialog {
  width: 50vw;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .small-dynamic-dialog {
    width: 35vw;
  }

  .medium-dynamic-dialog {
    width: 64vw;
  }
}

@media (max-width: 992px) {
  .small-dynamic-dialog {
    width: 40vw;
  }

  .medium-dynamic-dialog {
    width: 64vw;
  }

  .large-dynamic-dialog {
    width: 70vw;
  }
}

@media (max-width: 768px) {
  .small-dynamic-dialog {
    width: 52vw;
  }

  .medium-dynamic-dialog {
    width: 85vw;
  }

  .large-dynamic-dialog {
    width: 85vw;
  }
}

@media (max-width: 576px) {
  .small-dynamic-dialog {
    width: 85vw;
  }

  .medium-dynamic-dialog {
    width: 90vw;
  }

  .large-dynamic-dialog {
    width: 90vw;
  }
}
